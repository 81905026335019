export const GET_AUDIENCE_LIST = "GET_AUDIENCE_LIST";
export const GET_AUDIENCE = "GET_AUDIENCE";
export const CREATE_AUDIENCE = "CREATE_AUDIENCE";
export const RESET_AUDIENCE_STATUS = "RESET_AUDIENCE_STATUS";
export const BUILD_AUDIENCE = "BUILD_AUDIENCE";
export const SAVE_AUDIENCE = "SAVE_AUDIENCE";
export const DELETE_AUDIENCE = "DELETE_AUDIENCE";
export const UPDATE_AUDIENCE = "UPDATE_AUDIENCE";

export const CREATE_INSIGHT = "CREATE_INSIGHT";

export const ADD_VARIABLE_GROUP = "ADD_VARIABLE_GROUP";
export const REMOVE_VARIABLE_GROUP = "REMOVE_VARIABLE_GROUP";
export const RESET_VARIABLE_GROUP = "RESET_VARIABLE_GROUP";
export const CHANGE_OPERATOR = "CHANGE_OPERATOR";
export const CHANGE_BUILD_MODE = "CHANGE_BUILD_MODE";
export const CHANGE_GROUP_OPERATOR = "CHANGE_GROUP_OPERATOR";
export const CHANGE_GROUP_VARIABLES = "CHANGE_GROUP_VARIABLES";
export const CHANGE_NAME = "CHANGE_NAME"

export const LOADING = "LOADING";
export const RESET = "RESET";
export const ERROR = "ERROR";
