import { quantile } from "d3";
import { LocationScore } from "@/types";

export const extractBreaks = (scores: LocationScore[]) => {
  const values = scores.map(x => x.rate);
  return [
    quantile(values, 0.2) || 0.2,
    quantile(values, 0.4) || 0.4,
    quantile(values, 0.6) || 0.6,
    quantile(values, 0.9) || 0.9,
  ]
}

export const getFillColor = (breaks: number[]) => {
  return [
    "case",
    ["==", ["feature-state", "score"], null],
    "#ECF3F4",
    [
      "interpolate",
      ["linear"],
      ["feature-state", "score"],
      0, '#354a5f',
      breaks[0], '#486d84',
      breaks[1], '#5a92aa',
      breaks[2], '#6db9cf',
      breaks[3], '#82e1f2',
    ]
  ]
}
