import React from "react";

import { Skeleton } from "@mui/material";
import Grid from "@mui/material/Grid2";


export const DiscoverSkeleton: React.FC = () => {

  return (
    <>
      <Grid size={12}>
        <Skeleton
          variant="rounded"
          width={240}
          height={32}
        />
      </Grid>
      {Array(8).fill(0).map((_, index) => (
        <Grid key={index}>
          <Skeleton
            variant="rounded"
            width={220}
            height={140}
          />
        </Grid>
      ))}
    </>
  )

}
