import React from "react";

import { Divider, MenuItem, TextField } from "@mui/material";

import { operators } from "@/utils";


interface QueryGroupOperatorProps {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const QueryGroupOperator: React.FC<QueryGroupOperatorProps> = (
  {
    value,
    onChange
  }
) => {
  return (
    <Divider>
      <TextField
        size="small"
        color="secondary"
        select
        value={value}
        onChange={onChange}
        sx={{ minWidth: '80px' }}
      >
        {operators.map((operator) => (
          <MenuItem key={operator} value={operator}>
            {operator}
          </MenuItem>
        ))}
      </TextField>
    </Divider>
  )
}


export default QueryGroupOperator
