import React from "react";

import { Skeleton } from "@mui/material";
import Grid from "@mui/material/Grid2";


export const BuildSkeleton: React.FC = () => {

  return (
    <Grid container spacing={2}>
      <Grid size={12}>
        <Skeleton
          variant="rounded"
          width={240}
          height={32}
        />
      </Grid>
      <Grid size={12}>
        <Skeleton
          variant="rounded"
          height={210}
        />
      </Grid>
      <Grid size={12}>
        <Skeleton
          variant="rounded"
          height={52}
        />
      </Grid>
    </Grid>
  )

}
