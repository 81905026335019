import React, {useEffect, useState} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import {Alert, Checkbox, Collapse, Divider, FormControlLabel, SelectChangeEvent, Stack, Typography} from "@mui/material";
import Grid from "@mui/material/Grid2";

import { ExportSkeleton } from "./ExportSkeleton";
import { exportAudience } from "@/api";
import { HelpText, SegmentBox, useActivePage, VariableSelection } from "@/components/atoms";
import { PageContainer } from "@/components/containers";
import { AudienceMiniStats, MenuCard } from "@/components/molecules";
import { useAudience, useVariables } from "@/hooks";
import { AudienceSegment, Variable } from "@/types";
import { defaultSegment, exportOptions, getAudienceBreadcrumb } from "@/utils";



const ExportPage: React.FC = () => {

  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();

  let { audienceId } = useParams<{audienceId: string}>();
  const { audience, loading, error } = useAudience(audienceId);
  const activePage = useActivePage();
  const breadcrumbs = getAudienceBreadcrumb(activePage, audience, loading, 'Export');

  const [segment, setSegment] = useState<AudienceSegment>(defaultSegment);
  const [segmentNum, setSegmentNum] = useState<number>(0);

  const [exporting, setExporting] = useState<boolean>(false);
  const [exportError, setExportError] = useState<string | null >(null);

  const {variables, loading: variablesLoading, error: variablesError} = useVariables();
  const [addVariables, setAddVariables] = useState<boolean>(false);
  const [addSummary, setAddSummary] = useState<boolean>(false);
  const [selection, setSelection] = useState<Variable[]>([])

  useEffect(() => {
    if (audience) {
      audience.segments.length > 0 && setSegment(audience.segments[0])
      setSelection(audience.var_groups.map(v => v.variables).flat())
    }
  }, [audience]);

  useEffect(() => {
    if (audience && audience.segments.length > 0) {
      if (segment.segment_id !== 0) {
        setSegmentNum(audience.segments.map(x => x.segment_id).indexOf(segment.segment_id));
      } else {
        setSegmentNum(0);
      }
    }
  }, [audience, segment]);

  const handleSegmentSelect = (event: SelectChangeEvent) => {
    setSegment( audience?.segments.find((option) => option.segment_name === event.target.value) || defaultSegment);
  };

  const handleAddVariables = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAddVariables(event.target.checked);
  };

  const handleAddSummary = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAddSummary(event.target.checked);
  };

  const handleVariableSelect = (variables: Variable[]) => {
    setSelection(variables);
  }

  const handleVariableDelete = (variable: Variable) => {
    setSelection(prevState => prevState.filter(v => v.tag_id !== variable.tag_id));
  }

  const handleExport = async (exportOption: string) => {
    if (audience) {
      setExporting(true);
      setExportError(null);
      const accessToken = await getAccessTokenSilently();
      const { data, error } = await exportAudience(
        accessToken,
        audience.audience_id,
        exportOption,
        addVariables && selection.length > 0 ? selection : [],
        addSummary,
        segmentNum
      );

      if (data) {
        window.open(data.url, "_blank");
        setExporting(false);
      }

      if (error) {
        setExportError(error.message);
        setExporting(false);
        console.error("Error exporting audience", error);
      }
    }
  };


  const actionButtons = (
    <Stack direction="row" spacing={2}>
      {/*<Button*/}
      {/*  variant="contained"*/}
      {/*  size="small"*/}
      {/*  disableElevation*/}
      {/*  startIcon={<ArrowBackIosNew />}*/}
      {/*  onClick={() => navigate(-1)}*/}
      {/*>*/}
      {/*  Back*/}
      {/*</Button>*/}
    </Stack>
  )

  return (
    <PageContainer
      breadcrumbs={breadcrumbs}
      actions={actionButtons}
      loading={exporting}
      loadingText={'Preparing your data'}
      scope="export"
    >
      {loading ?
        <ExportSkeleton />
        :
        <Grid container spacing={2}>
          {audience &&
            <>
              <Grid size={12} display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" mb={1}>
                <Stack spacing={0.5}>
                  <Typography variant="h6">{audience.audience_name}</Typography>
                  {audience.segments.length > 0 &&
                    <SegmentBox
                      label={'Segment'}
                      value={segment.segment_name}
                      options={audience.segments}
                      onChange={handleSegmentSelect}
                      showSelected={true}
                    />
                  }
                </Stack>
                {audience.audience_size &&
                  <AudienceMiniStats audienceSize={audience.audience_size[segmentNum]} />
                }
              </Grid>
              {exportError &&
                <Grid>
                  <Alert variant="filled" severity="error">
                    There was a problem creating this export, please try again and contact the systems team if the problem persists
                  </Alert>
                </Grid>
              }
              {!audience.export &&
                <Grid>
                  <Alert variant="filled" severity="info">
                    Export is currently disabled for this audience, please rebuild the audience in order to enable exports.
                  </Alert>
                </Grid>
              }
              <Grid size={12}>
                <HelpText
                  text="Configure your Download"
                  helpText="
                  Configure the format and content of the file that you download.
                  "
                  variant="body1"
                />
              </Grid>
              <Grid size={12}>
                <Stack direction="row" spacing={4}>
                  <FormControlLabel
                    control={<Checkbox checked={addVariables} size="small" onChange={handleAddVariables}/>}
                    disableTypography
                    label={
                      <HelpText
                        text="Add Variables"
                        helpText="
                        Add the score for each variable to each geo as a column in the exported file
                        "
                        variant="body2"
                      />
                    }
                  />
                  {addVariables &&
                    <FormControlLabel
                      control={<Checkbox checked={addSummary} size="small" onChange={handleAddSummary}/>}
                      disableTypography
                      label={
                        <HelpText
                          text="Add Summary Score"
                          helpText="
                          Summarise the scores for the selected variables into a single score and add this to the
                          exported file
                          "
                          variant="body2"
                        />
                      }
                    />
                  }
                </Stack>
              </Grid>
              <Grid size={12}>
                <Collapse in={addVariables}>
                  <Grid container size={12} spacing={1}>
                    <VariableSelection
                      variables={variables}
                      selection={selection}
                      onSelect={handleVariableSelect}
                      onDelete={handleVariableDelete}
                    />
                    <Grid size={12} />
                  </Grid>
                </Collapse>
              </Grid>
              <Grid size={12}>
                <HelpText
                  text="Select an Export Type"
                  helpText="
                  Select the type of file you want to export. Each export type contains the geo data for your audience
                  in the most relevant format for the channel.
                  "
                  variant="body1"
                />
              </Grid>
              <Grid container size={12} spacing={2}>
                {exportOptions.map((option, index) => (
                  <Grid key={option.name}>
                    <MenuCard
                      key={index}
                      title={option.label}
                      text={option.description}
                      icon={option.icon}
                      disabled={!audience.export}
                      onClick={() => handleExport(option.name)}
                    />
                  </Grid>
                ))}
              </Grid>
            </>
          }
        </Grid>
      }
    </PageContainer>

  );
}

export default ExportPage;
