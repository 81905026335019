import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { ApiError, ApiResponse } from "@/types";

/**
 * Creates an ApiResponse object from an AxiosResponse.
 * @param response - The Axios response object.
 * @returns An ApiResponse object.
 */
const createApiResponse = (response: AxiosResponse): ApiResponse => ({
  data: response.data,
  error: null,
});

/**
 * Fetches data from an API using the provided Axios request configuration.
 * @param config - Configuration for the Axios request.
 * @returns A promise that resolves to the ApiResponse type.
 */
export const fetchData = async (
  config: AxiosRequestConfig
): Promise<ApiResponse> => {
  try {
    const response = await axios(config);
    return createApiResponse(response);
  } catch (error) {
    return handleAxiosError(error);
  }
};

/**
 * Handles errors thrown by Axios requests.
 * @param error - The error object, potentially an AxiosError.
 * @returns An ApiResponse object with the error field populated.
 */
const handleAxiosError = (error: unknown): ApiResponse => {
  if (axios.isAxiosError(error)) {
    return {
      data: null,
      error: { message: extractErrorMessageFromAxiosError(error) },
    };
  }

  return {
    data: null,
    error: {
      message:
        error instanceof Error ? error.message : "An unexpected error occurred",
    },
  };
};

/**
 * Extracts a meaningful error message from an AxiosError.
 * @param axiosError - The AxiosError object.
 * @returns A string representing the error message.
 */
const extractErrorMessageFromAxiosError = (axiosError: AxiosError): string => {
  if (axiosError.response) {
    return (
      (axiosError.response.data as ApiError)?.message ||
      axiosError.response.statusText
    );
  }

  return axiosError.message || "HTTP request failed";
};
