import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

import {
  Avatar,
  Collapse,
  Divider,
  List,
  ListItem,
  ListSubheader,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

import { HelpBox, Link } from "@/components/atoms";
import { Navigation } from "@/types";
import { RouterContext } from "@/context";
import {
  getDrawerTransition,
  getItemTitle,
  getPageItemFullPath,
  hasSelectedNavigationChildren,
  isPageItemAllowed,
  isPageItemSelected,
} from "@/utils";

interface BaseLayoutProps {
  navigation: Navigation;
  isExpanded: boolean;
  onSelect: () => void;
  selectedItemId: string;
  depth?: number;
  basePath?: string;
}

const NavigationMenu: React.FC<BaseLayoutProps> = (
  {
    navigation,
    isExpanded,
    onSelect,
    selectedItemId,
    depth = 0,
    basePath = ''
  }
) => {

  const { user } = useAuth0();
  const routerContext = React.useContext(RouterContext);
  const pathname = routerContext?.pathname ?? '/';


  const initialExpandedItemIds = React.useMemo(
    () =>
      navigation
        .map((navItem, navItemIndex) => ({
          navItem,
          originalIndex: navItemIndex,
        }))
        .filter(({ navItem }) =>
          hasSelectedNavigationChildren(navItem, basePath, pathname),
        )
        .map(({ originalIndex }) => `${depth}-${originalIndex}`),
    [basePath, depth, pathname, navigation],
  );

  const [expandedItemIds, setExpandedItemIds] = React.useState(initialExpandedItemIds,);

  const handleItemClick = React.useCallback(
    (itemId: string) => () => {
      setExpandedItemIds((previousValue) =>
        previousValue.includes(itemId)
          ? previousValue.filter((previousValueItemId) => previousValueItemId !== itemId)
          : [...previousValue, itemId],
      );
    },
    [],
  );

  return (
    <List sx={{ padding: 0, mb: depth === 0 ? 4 : 1, pl: 2 * depth }}>
      {navigation.map((navItem, navIdx) => {

        if (navItem.kind === 'header') {
          return (
            <ListSubheader
              key={`nav-subheader-${depth}-${navIdx}`}
              component="div"
              sx={{
                height: isExpanded ? 40 : 0,
                ...getDrawerTransition(isExpanded, 'height'),
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                zIndex: 2,
                background: 'none'
              }}
            >
              {navItem.title}
            </ListSubheader>
          )
        }

        if (navItem.kind === 'divider') {
          return (
            <Divider
              key={`nav-divider-${depth}-${navIdx}`}
              sx={{
                borderBottomWidth: 2,
                mx: 2,
                mt: 1,
                mb: 1,
                ...getDrawerTransition(isExpanded, 'margin')
              }}
            />
          )
        }

        const navItemFullPath = getPageItemFullPath(basePath, navItem);
        const navItemId = `nav-item-${depth}-${navIdx}`;
        const navItemTitle = getItemTitle(navItem);

        const isNestedNavigationExpanded = expandedItemIds.includes(navItemId);

        const nestedNavigationCollapseIcon = isNestedNavigationExpanded ? (
          <ExpandLess />
        ) : (
          <ExpandMore />
        );

        const hasPermission = user ? isPageItemAllowed(navItem, user) : false;
        const isSelected = isPageItemSelected(navItem, basePath, pathname);

        if (isSelected && !selectedItemId) {
          selectedItemId = navItemId;
        }

        const listItem = (
          <ListItem
            sx={{
              py: 0.5,
              px: 1,
              overflowX: 'hidden',
            }}
          >
            <ListItemButton
              selected={isSelected && (!navItem.children || !isExpanded)}
              disabled={!hasPermission}
              sx={{
                px: 1.4,
                height: 48,
                borderRadius: 2
              }}
              {...(navItem.children && isExpanded
                ? {
                  onClick: handleItemClick(navItemId),
                }
                : {
                  LinkComponent: Link,
                  href: navItemFullPath,
                  onClick: onSelect,
                })}
            >
              {navItem.icon || !isExpanded ? (
                <ListItemIcon
                  sx={{
                    minWidth: 34,
                    mr: 1.2,
                  }}
                >
                  {navItem.icon ?? null}
                  {!navItem.icon && !isExpanded ? (
                    <Avatar
                      sx={{
                        width: 34 - 7,
                        height: 34 - 7,
                        fontSize: 12,
                        ml: '-2px',
                      }}
                    >
                      {navItemTitle
                        .split(' ')
                        .slice(0, 2)
                        .map((itemTitleWord) => itemTitleWord.charAt(0).toUpperCase())}
                    </Avatar>
                  ) : null}
                </ListItemIcon>
              ) : null}
              <ListItemText
                primary={navItemTitle}
                sx={{
                  whiteSpace: 'nowrap',
                  zIndex: 1,
                }}
              />
              {navItem.action && isExpanded ? navItem.action : null}
              {navItem.children && isExpanded
                ? nestedNavigationCollapseIcon
                : null}
            </ListItemButton>
          </ListItem>
        );

        return (
          <React.Fragment key={navItemId}>
            {!isExpanded ? (
              <HelpBox title={navItemTitle} placement="right">
                {listItem}
              </HelpBox>
            ) : (
              listItem
            )}

            {navItem.children && isExpanded ? (
              <Collapse in={isNestedNavigationExpanded} timeout="auto" unmountOnExit>
                <NavigationMenu
                  navigation={navItem.children}
                  isExpanded={isExpanded}
                  onSelect={onSelect}
                  selectedItemId={selectedItemId}
                  depth={depth + 1}
                  basePath={navItemFullPath}
                />
              </Collapse>
            ) : null}

          </React.Fragment>
        )


      })}
    </List>
  );
}

export default NavigationMenu;
