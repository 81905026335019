import React from 'react';

import { useTheme } from '@mui/material/styles';
import { Tooltip as T } from "@mui/material";
import { TooltipProps as TP } from "@mui/material/Tooltip";

type HelpBoxProps = {
  title: React.ReactNode;
  placement?: TP['placement'];
  children: React.ReactElement<any, any>;
};

const HelpBox: React.FC<HelpBoxProps> = ({title, placement = "bottom", children}) => {

  const theme = useTheme();

  return (
    <T
      title={title}
      placement={placement}
      slotProps={{
        tooltip: {
          sx: {
            backgroundColor: theme.palette.info.main,
            color: theme.palette.info.contrastText,
            borderRadius: '4px',
            maxWidth: "200px",
            textAlign: "center"
          }
        }
      }}
    >
      {children}
    </T>
  )
}

export default HelpBox;
