import {ActivePage, Audience, Breadcrumb, VariableGroup} from "@/types";


export const defaultAudience: Audience = {
  audience_id: "",
  audience_name: "",
  created_at: "",
  build_mode: "balanced",
  operator: "AND",
  var_groups: [{ id: 1, variables: [], operator: "AND" }],
  segment: false,
  segments: [],
  audience_type: "build",
  export: true,
};

export const defaultVariableGroup = (id: number) => {
  return {
    id: id,
    variables: [],
    operator: "AND"
  }
}

/**
 * Build breadcrumbs for an audience page
 */
export function getAudienceBreadcrumb(
  activePage: ActivePage | null,
  audience: Audience | null,
  loading: boolean,
  page?: string
): Breadcrumb[] {

  const newAudience = !audience || audience.audience_id === '' || audience.audience_name === '';

  if (!activePage)
    return []

  if (loading)
    return activePage.breadcrumbs

  const audiencePath = `${activePage.path}/${newAudience ? 'new' : audience.audience_id}`;
  const audienceTitle = newAudience ? 'New' : audience.audience_name
  const audienceBreadcrumb = {title: audienceTitle, path: audiencePath}

  if (page) {
    const pagePath = `${audiencePath}/${page}`;
    const pageBreadcrumb = {title: page, path: pagePath}

    return [...activePage.breadcrumbs, audienceBreadcrumb, pageBreadcrumb]
  } else {
    return [...activePage.breadcrumbs, audienceBreadcrumb]
  }
}

/**
 * Filter a list of audiences using search terms, multiple filters and sort
 */
export const filterAudiences = (
  audiences: Audience[] | null,
  searchText?: string,
  filterBy?: string,
  sortBy?: string
) => {

  // Filter by search text
  let filteredAudiences = audiences?.filter((audience) =>
    audience.audience_name
      .toLowerCase()
      .includes(searchText?.toLowerCase() || "")
  );

  // Filter by filter
  if (filterBy) {
    filteredAudiences = filteredAudiences?.filter(
      (audience) => audience.audience_type === filterBy
    );
  }

  // sort by sort
  if (sortBy) {
    if (sortBy === "name") {
      filteredAudiences?.sort((a, b) => a.audience_name.localeCompare(b.audience_name));
    } else if (sortBy === "date") {
      filteredAudiences?.sort(
        (a, b) =>
          new Date(b.created_at || 0).getTime() - new Date(a.created_at || 0).getTime()
      );
    }
  }

  return filteredAudiences;
};
