import React from "react";

import {
  Box,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography
} from "@mui/material";
import { useTheme } from "@mui/material/styles";


interface FilterOption {
  name: string;
  label: string;
}


interface FilterBoxProps {
  label: string;
  value: string;
  options: FilterOption[];
  onChange: (event: SelectChangeEvent) => void;
  startIcon?: React.ReactNode;
  showSelected?: boolean;
  size?: 'medium' | 'small'
}


const FilterBox: React.FC<FilterBoxProps> = (
  {
    label,
    value,
    options,
    onChange,
    startIcon,
    showSelected = false,
    size="small"
  }
) => {

  const theme = useTheme();

  return (
    <Select
      size={size}
      variant="outlined"
      value={value}
      onChange={onChange}
      displayEmpty
      renderValue={(value: string) => (
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
        >
          {startIcon && (
            <Box display="flex" mr={0.5}>
              {startIcon}
            </Box>
          )}
          {showSelected && value !== '' ?
            <Typography color="primary">{options.find((option) => option.name === value)?.label}</Typography>
          :
            <Typography color="textSecondary">{label}</Typography>
          }
        </Box>
      )}
      sx={{
        minWidth: "200px",
        "& > fieldset": {
          borderColor: value !== "" ? theme.palette.primary.main : "",
        },
      }}
    >
      {options.map((option) => (
        <MenuItem key={option.name} value={option.name}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  )
}

export default FilterBox;
