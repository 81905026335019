import { AxiosRequestConfig } from "axios";
import { fetchData } from "@/utils";

import { ApiError, Variable } from "@/types";


type ExportApiResponse = {
  data: { audience_id: string, url: string };
  error: ApiError | null;
};

export const exportAudience = async (
  accessToken: string,
  audienceId: string,
  exportMode: string,
  variables: Variable[],
  summarize: boolean,
  segment: number,
): Promise<ExportApiResponse> => {

  const params = new URLSearchParams({
    audience_id: audienceId,
    export_mode: exportMode,
  });
  if (segment > 0) params.append("segment", String(segment))
  if (variables.length > 0) params.append("variables", variables.map(x => x.tag_id).join(','))
  if (summarize) params.append("summarize", "true")

  let url = `${process.env.REACT_APP_API_URL}/export?${params.toString()}`;

  const config: AxiosRequestConfig = {
    method: "GET",
    url,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return fetchData(config);
};
