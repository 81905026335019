import React from "react";

import {
  InputAdornment,
  TextField
} from "@mui/material";
import { Search } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";


interface SearchBoxProps {
  value: string;
  onSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
  size?: 'medium' | 'small';
}


const SearchBox: React.FC<SearchBoxProps> = ({value, onSearch, size='small'}) => {

  const theme = useTheme();


  return (
    <TextField
      variant="outlined"
      size={size}
      color="primary"
      value={value}
      onChange={onSearch}
      placeholder="Search"
      slotProps={{
        input: {
          startAdornment: (
            <InputAdornment position="start">
              <Search fontSize="small" />
            </InputAdornment>
          )
        }
      }}
      sx={{
        input: {
          maxWidth: "200px",
          "& > fieldset": {
            borderColor:
              value !== "" ? theme.palette.primary.main : "",
          },
          '&::placeholder': {
            color: theme.palette.text.secondary,
            opacity: 1
          }
        }
      }}
    />
  )
}

export default SearchBox;
