import React from "react";
import {Box, CircularProgress} from "@mui/material";


const Spinner = () => (
  <Box>
      <CircularProgress size={200}/>
  </Box>
);

export default Spinner;
