import React, {useEffect, useState} from "react";
import { sortBy } from "lodash";

import { Box, Card, CardContent, CardHeader, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useTheme } from "@mui/material/styles";

import { HelpBox } from "@/components/atoms";
import { PenPortraitInsightVariable } from "@/types";
import { toIndex, toPercentage } from "@/utils";

interface PenPortraitCardProps {
  title: string;
  variables: PenPortraitInsightVariable[]
  score: string;
  levels: number[];
}


const PenPortraitCard: React.FC<PenPortraitCardProps> = (
  {
    title,
    variables,
    score,
    levels
  }
) => {

  const theme = useTheme();

  const [scores, setScores] = useState<PenPortraitInsightVariable[]>([]);
  const formatFunc = score === 'rate' ? toPercentage : toIndex

  useEffect(() => {
    let filtered = variables.filter(
      x => score === 'rate' ? x[score as keyof PenPortraitInsightVariable] > 0 : x[score as keyof PenPortraitInsightVariable] > 0
    ).filter(
      x => levels.includes(x.tag_level)
    )
    setScores(sortBy(filtered, score).reverse())
  }, [score, variables, levels]);

  if (scores.length === 0) {
    return null;
  } else {
    return (
      <Grid size={{ xs: 6, lg: 4, xl: 4}} key={title}>
        <Card
          sx={{
            border: 1,
            borderRadius: 1,
            borderColor: theme.palette.divider,
            height: "200px"
          }}
        >
          <CardHeader
            title={title}
            slotProps={{ title: {variant: 'body1'} }}
            sx={{ height: '80px', alignItems: 'flex-start' }}
          />
          <CardContent sx={{ pb: 2, "&:last-child": {pb: 2} }}>
            <Grid container spacing={1}>
              {scores.slice(0, 6).map((variable) => (
                <Grid size={2} key={`${title}-${variable.tag_id}`}>
                  <HelpBox title={`${variable.tag_name} (${formatFunc(Number(variable[score as keyof PenPortraitInsightVariable]))})`}>
                    <Stack spacing={1}>
                      <Box
                        component="img"
                        src={variable.image}
                        alt={variable.tag_name}
                        minWidth="40px"
                        minHeight="40px"
                        maxWidth="100%"
                        maxHeight="100%"
                        display="block"
                        style={{ borderRadius: 5 }}
                      >
                      </Box>
                      <Typography
                        variant="caption"
                        sx={{
                          fontSize: '8px',
                          textAlign: "center",
                          display: '-webkit-box',
                          overflow: 'hidden',
                          WebkitBoxOrient: 'vertical',
                          WebkitLineClamp: 2,
                        }}
                      >
                        {variable.tag_name}
                      </Typography>
                    </Stack>
                  </HelpBox>
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    )
  }
}

export default PenPortraitCard;
