import { AxiosRequestConfig } from "axios";

import {ApiError, Audience, AudienceBuild} from "@/types";
import { fetchData } from "@/utils";

type GetAudiencesApiResponse = {
  data: Audience[];
  error: ApiError | null;
};

export const getAudiences = async (
  accessToken: string,
  audienceType: string
): Promise<GetAudiencesApiResponse> => {
  let url = `${process.env.REACT_APP_API_URL}/v2/audience?audience_type=${audienceType}`;

  const config: AxiosRequestConfig = {
    method: "GET",
    url,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return fetchData(config);
};


type GetAudienceApiResponse = {
  data: Audience;
  error: ApiError | null;
};

export const getAudience = async (
  accessToken: string,
  audienceId: string
): Promise<GetAudienceApiResponse> => {
  let url = `${process.env.REACT_APP_API_URL}/v2/audience/${audienceId}`;

  const config: AxiosRequestConfig = {
    method: "GET",
    url,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return fetchData(config);
};


type DeleteAudienceApiResponse = {
  data: Audience[];
  error: ApiError | null;
};

export const deleteAudience = async (
  accessToken: string,
  name: string,
  id: string,
  type: string
): Promise<DeleteAudienceApiResponse> => {
  const url = `${process.env.REACT_APP_API_URL}/v2/audience?audience_id=${id}&audience_name=${name}&audience_type=${type}`;

  const config: AxiosRequestConfig = {
    method: "DELETE",
    url,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return fetchData(config);
};


type BuildAudienceApiResponse = {
  data: AudienceBuild;
  error: ApiError | null;
};

export const buildAudience = async (
  accessToken: string,
  queryGroups: string[],
  threshold: number,
  operator: string,
  audienceId: string | undefined,
): Promise<BuildAudienceApiResponse> => {

  // TODO pass down the full audience in a post request to give us more flexibility in the build

  let url = [
    `${process.env.REACT_APP_API_URL}/v2/build?query=${queryGroups.join("|")}`,
    `operator=${operator}`,
    `threshold=${threshold}`,
    audienceId ? `audience_id=${audienceId}` : null
  ].join('&')

  const config: AxiosRequestConfig = {
    method: "GET",
    url,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return fetchData(config);
};


type SaveAudienceApiResponse = {
  data: Audience[];
  error: ApiError | null;
};

export const saveAudience = async (
  accessToken: string,
  audience: Audience,
  rebuild: boolean,
): Promise<SaveAudienceApiResponse> => {

  const url = `${process.env.REACT_APP_API_URL}/v2/audience?rebuild=${rebuild}`;

  const config: AxiosRequestConfig = {
    method: "POST",
    url,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    data: audience,
  };

  return fetchData(config);
};
