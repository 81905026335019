import { User } from "@auth0/auth0-react";

export const getPermissions = (user: User) => {
  return user[`https://${process.env.REACT_APP_AUTH0_DOMAIN}/permissions`];
}

export const checkPermission = (user: User, permission: string): boolean => {
  if (permission === '') {
    return true;
  } else {
    return user[`https://${process.env.REACT_APP_AUTH0_DOMAIN}/permissions`].includes(`connect:${permission}`);
  }
}
