import React from "react";

import {
  Box,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { AudienceSegment } from "@/types";



interface SegmentBoxProps {
  label: string;
  value: string;
  options: AudienceSegment[];
  onChange: (event: SelectChangeEvent) => void;
  startIcon?: React.ReactNode;
  showSelected?: boolean;
  size?: 'medium' | 'small'
}


const SegmentBox: React.FC<SegmentBoxProps> = (
  {
    label,
    value,
    options,
    onChange,
    startIcon,
    showSelected = false,
    size="small"
  }
) => {

  const theme = useTheme();

  return (
    <Select
      size={size}
      variant="outlined"
      value={value}
      onChange={onChange}
      displayEmpty
      renderValue={(value: string) => (
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
        >
          {startIcon && (
            <Box display="flex" mr={0.5}>
              {startIcon}
            </Box>
          )}
          {showSelected && value !== '' ?
            <Typography color="primary">{options.find((option) => option.segment_name === value)?.segment_name}</Typography>
          :
            <Typography color="textSecondary">{label}</Typography>
          }
        </Box>
      )}
      sx={{
        minWidth: "200px",
        "& > fieldset": {
          borderColor: value !== "" ? theme.palette.primary.main : "",
        },
      }}
    >
      {options.map((option) => (
        <MenuItem key={option.segment_name} value={option.segment_name}>
          {option.segment_name}
        </MenuItem>
      ))}
    </Select>
  )
}

export default SegmentBox;
