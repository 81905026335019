import React from "react";

import {
  Box,
  Breadcrumbs,
  Link,
  Typography,
} from "@mui/material";
import { NavigateNext } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";

import { Breadcrumb } from "@/types";
import { Link as NavLink, useActivePage } from "@/components/atoms";


interface ActionBarProps {
  breadcrumbs?: Breadcrumb[];
  actions?: React.ReactElement;
}


const ActionBar: React.FC<ActionBarProps> = ({breadcrumbs, actions}) => {

  const theme = useTheme();
  const activePage = useActivePage();

  const useBreadcrumbs = breadcrumbs ?? activePage?.breadcrumbs ?? [];


  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        p: 2,
        borderWidth: 0,
        borderBottomWidth: 1,
        borderStyle: 'solid',
        borderColor: theme.palette.divider,
      }}
    >
      <Breadcrumbs separator={<NavigateNext fontSize="small" />}>
        {useBreadcrumbs.map((item, index) => (
          item.path ?
            <Link
              key={index}
              component={NavLink}
              underline="hover"
              color="inherit"
              href={item.path}
            >
              {item.title}
            </Link>
            :
            <Typography key={index}>
              {item.title}
            </Typography>
        ))}
      </Breadcrumbs>
      {actions}
    </Box>
  );

}

export default ActionBar;
