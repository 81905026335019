import React, {useEffect, useState} from "react";
import { sortBy } from "lodash";

import { Box, Card, CardContent, CardHeader, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useTheme, lighten, darken, emphasize, alpha } from "@mui/material/styles";

import { HelpBox } from "@/components/atoms";
import { MindsetResult} from "@/types";
import { segmentColors, toPercentage, toIndex } from "@/utils";

interface SegmentCardProps {
  title: string;
  variables: MindsetResult[];
}


const SegmentCard: React.FC<SegmentCardProps> = (
  {
    title,
    variables,
  }
) => {

  const theme = useTheme();

  const topMindset = sortBy(variables, 'score').reverse()[0]
  const topMindsetIndex = sortBy(variables, 'tag_id').map(x => x.tag_id).indexOf(topMindset.tag_id)

  return (
    <Card
      sx={{
        border: 1,
        borderRadius: 1,
        borderColor: theme.palette.divider,
        height: "200px"
      }}
    >
      <CardHeader
        title={title}
        slotProps={{ title: {variant: 'body1'} }}
      />
      <CardContent sx={{ pb: 2, "&:last-child": {pb: 2} }}>
        <Stack spacing={2}>
          <Stack direction="row" spacing={1}>
            <Typography>
              Core Mindset:
            </Typography>
            <Typography style={{ color: segmentColors[topMindsetIndex] }}>
              {topMindset.tag_name}
            </Typography>
          </Stack>
          <Stack direction="row" spacing={1}>
            {sortBy(variables, 'tag_id').map((segment, index ) => (
              <HelpBox
                key={`segment-${index}`}
                title={`${segment.tag_name} (${toPercentage(segment.rate)}) (${toIndex(segment.score)})`}
              >
                <Box
                  width={`calc(100% * ${segment.rate})`}
                  height="40px"
                  sx={{
                    backgroundColor: segmentColors[index]
                  }}
                  style={{ borderRadius: 5 }}
                />
              </HelpBox>
            ))}
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  )

}

export default SegmentCard;
