import React, { useEffect } from "react";
import { times } from "lodash";

import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { useMap, MapboxGeoJSONFeature } from "react-map-gl";

import { StatBox } from "@/components/atoms";
import {extractBreaks, getFillColor, toPercentage} from "@/utils";
import {LocationResult} from "@/types";


type OverlayProps = {
  scores: LocationResult;
  selected?: MapboxGeoJSONFeature;
  clearSelected: () => void;
  breaks: number[];
  firstTime: boolean;
};

export const Overlay: React.FC<OverlayProps> = (
  {
    scores,
    selected,
    clearSelected,
    breaks,
    firstTime,
  }
) => {

  const { current: map } = useMap();
  const theme = useTheme();

  useEffect(() => {
    if (!firstTime) {
      map && map.isStyleLoaded() && resetMap();
      map &&
      scores.area.forEach((value) => {
        map.setFeatureState(
          {
            id: value.gid,
            source: "area-source",
            sourceLayer: "postal_area_test",
          },
          { score: value.rate, name: value.name, level: value.level }
        );
      });
      map && map.getMap().setPaintProperty("area-base", "fill-color", getFillColor(extractBreaks(scores.area)));
      map &&
      scores.district.forEach((value) => {
        map.setFeatureState(
          {
            id: value.gid,
            source: "district-source",
            sourceLayer: "postal_district_test",
          },
          { score: value.rate, name: value.name, level: value.level }
        );
      });
      map && map.getMap().setPaintProperty("district-base", "fill-color", getFillColor(extractBreaks(scores.district)));
      map &&
      scores.sector.forEach((value) => {
        map.setFeatureState(
          {
            id: value.gid,
            source: "sector-source",
            sourceLayer: "postal_sector_test",
          },
          { score: value.rate, name: value.name, level: value.level }
        );
      });
      map && map.getMap().setPaintProperty("sector-base", "fill-color", getFillColor(extractBreaks(scores.sector)));
    }
  }, [firstTime, map, scores])

  const resetMap = () => {
    clearSelected();
    map && [...times(121)].forEach(value => {
      map.setFeatureState(
        {id: value, source: 'area-source', sourceLayer: 'postal_area_test'},
        {score: null, name: null, level: null}
      );
    });
    map && [...times(2816)].forEach(value => {
      map.setFeatureState(
        {id: value, source: 'district-source', sourceLayer: 'postal_district_test'},
        {score: null, name: null, level: null}
      );
    });
    map && [...times(9469)].forEach(value => {
      map.setFeatureState(
        {id: value, source: 'sector-source', sourceLayer: 'postal_sector_test'},
        {score: null, name: null, level: null}
      );
    });
  };

  return (
    <>
      {selected &&
        <Box
          sx={{
            backgroundColor: theme.palette.background.default,
            position: "absolute",
            top: 12,
            right: 12
          }}
        >
          <StatBox
            name={selected.state?.name || ''}
            value={selected.state?.score || 0}
            valueFormatter={toPercentage}
          />
        </Box>
      }
      {breaks.length > 0 &&
        <Box
          sx={{
            position: "absolute",
            bottom: 40,
            left: 12
          }}
        >
          <Box
            sx={{
              width: '150px',
              height: '10px',
              borderRadius: '10px',
              background: 'linear-gradient(to right, #354a5f, #486d84, #6db9cf, #82e1f2)'
            }}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant="caption">{toPercentage(0, 0)}</Typography>
            <Typography variant="caption">{toPercentage(breaks[0], 0)}</Typography>
            <Typography variant="caption">{toPercentage(breaks[1], 0)}</Typography>
            <Typography variant="caption">{toPercentage(breaks[2], 0)}</Typography>
            <Typography variant="caption">{toPercentage(breaks[3], 0)}</Typography>
          </Box>
        </Box>
      }
    </>
  )
}
