import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import { getDiscoverVariables, getVariables } from "@/api";
import { Variable } from "@/types";

// TODO move variables to a context

export const useVariables = () => {
  const { getAccessTokenSilently } = useAuth0();

  const [variables, setVariables] = useState<Variable[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchVariables = async () => {
        setLoading(true);
        setError(null);

        try {
          const accessToken = await getAccessTokenSilently();
          const { data, error } = await getVariables(accessToken);

          if (error)
            setError(error.message)

          setVariables(data);
        } catch (err) {
          setError((err as Error).message)
        } finally {
          setLoading(false);
        }
      }

      fetchVariables();

  }, [getAccessTokenSilently])

  return {
    variables, loading, error
  }
}


export const useDiscover = () => {
  const { getAccessTokenSilently } = useAuth0();

  const [variables, setVariables] = useState<Variable[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchDiscover = async () => {
      setLoading(true);
      setError(null);

      try {
        const accessToken = await getAccessTokenSilently();
        const { data, error } = await getDiscoverVariables(accessToken);

        if (error)
          setError(error.message)

        setVariables(data);
      } catch (err) {
        setError((err as Error).message)
      } finally {
        setLoading(false);
      }
    }

    fetchDiscover();

  }, [getAccessTokenSilently])

  return {
    variables, loading, error
  }
}
