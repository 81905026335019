import { PaletteColor } from "@mui/material";


export const segmentColors = [
  '#ff6c6c',
  '#3dd56d',
  '#9a5dff',
  '#ffbd45',
  '#3d9df3',
  '#fff835',
  '#20e7c5',
  '#24d4ff',
]

export const headerBackground = (palette: PaletteColor) => {
  return `linear-gradient(137deg, ${palette.main}, ${palette.dark})`;
}
