import React from 'react';

import {
  Backdrop,
  CircularProgress,
  Stack,
  Typography
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

type LoadingOverlayProps = {
  open: boolean
  text?: string;
};

const LoadingOverlay: React.FC<LoadingOverlayProps> = ({open, text}) => {

  const theme = useTheme();

  return (
    <Backdrop open={open} sx={{ zIndex: theme.zIndex.appBar - 1 }}>
      <Stack spacing={2} display="flex" alignItems="center">
        {text && <Typography>{text}</Typography>}
        <CircularProgress size={48} />
      </Stack>
    </Backdrop>
  )
}

export default LoadingOverlay;
