import { AxiosRequestConfig } from "axios";

import { ApiError, Variable } from "@/types";
import { fetchData } from "@/utils";

type GetVariablesApiResponse = {
  data: Variable[];
  error: ApiError | null;
};

export const getVariables = async (
  accessToken: string
): Promise<GetVariablesApiResponse> => {
  let url = `${process.env.REACT_APP_API_URL}/v2/tags`;

  const config: AxiosRequestConfig = {
    method: "GET",
    url,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return fetchData(config);
};


type GetDiscoverVariablesApiResponse = {
  data: Variable[];
  error: ApiError | null;
};

export const getDiscoverVariables = async (
  accessToken: string
): Promise<GetDiscoverVariablesApiResponse> => {
  let url = `${process.env.REACT_APP_API_URL}/v2/ots`;

  const config: AxiosRequestConfig = {
    method: "GET",
    url,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return fetchData(config);
};
