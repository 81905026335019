import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useTheme } from "@mui/material/styles";

import {
	Box,
	Divider,
	IconButton,
	Menu,
	MenuItem,
	Typography
} from "@mui/material";
import {
	DarkMode,
	LightMode,
	Logout,
	QuestionAnswer,
	Settings
} from "@mui/icons-material";

import { useThemeMode } from "@/context/Theme";

const UserMenu = () => {
	const theme = useTheme();
	const mode = theme.palette.mode;
	const { toggleDarkMode } = useThemeMode();
	const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
	const { logout } = useAuth0();

	const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElUser(event.currentTarget);
	};

	const handleCloseUserMenu = () => {
		setAnchorElUser(null);
	};

	const handleLogout = () => {
		logout({
			logoutParams: {
				returnTo: window.location.origin
			},
		}).then();
	};

	const handleContactSupport = () => {
		window.location.href = "mailto:support@starcount.com";
	};

	return (
		<>
			<IconButton onClick={handleOpenUserMenu}>
				<Settings />
			</IconButton>
			<Menu
				id="user-settings-menu"
				anchorEl={anchorElUser}
				anchorOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				keepMounted
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				open={Boolean(anchorElUser)}
				onClose={handleCloseUserMenu}
				sx={{ mt: "32px" }}
			>
				<MenuItem key="mode" onClick={toggleDarkMode}>
					<Box sx={{ display: "flex", alignItems: "center" }}>
						{mode === "dark" ? <LightMode /> : <DarkMode />}
						<Typography sx={{ ml: 1 }} textAlign="center">
							{`Set ${mode === "dark" ? "Light" : "Dark"} Mode`}
						</Typography>
					</Box>
				</MenuItem>
				<Divider />
				<MenuItem key="contact" onClick={handleContactSupport}>
					<Box sx={{ display: "flex", alignItems: "center" }}>
						<QuestionAnswer />
						<Typography sx={{ ml: 1 }} textAlign="center">
							Contact Support
						</Typography>
					</Box>
				</MenuItem>
				<Divider />
				<MenuItem key="logout" onClick={handleLogout}>
					<Box sx={{ display: "flex", alignItems: "center" }}>
						<Logout />
						<Typography sx={{ ml: 1 }} textAlign="center">
							Logout
						</Typography>
					</Box>
				</MenuItem>
			</Menu>
		</>
	);
};

export default UserMenu;
