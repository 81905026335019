import React from "react";

import {
  Box,
  Stack,
  Typography
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { HelpBox } from "@/components/atoms";
import { toRounded } from "@/utils";


interface SearchBoxProps {
  name: string;
  value: number;
  valueFormatter?: (value: number) => number | string;
  icon?: React.ReactNode;
}


const MiniStatBox: React.FC<SearchBoxProps> = ({name, value, valueFormatter = toRounded, icon}) => {

  const theme = useTheme();

  return (
    <HelpBox title={name}>
      <Stack
        direction="row"
        alignItems="center"
        spacing={2}
        border={1}
        borderRadius={1}
        borderColor={theme.palette.divider}
        p={1}
        minWidth={"120px"}
      >
        {icon}
        <Typography variant="h6">{valueFormatter(value)}</Typography>
      </Stack>
    </HelpBox>
  )
}

export default MiniStatBox;
