import React, { useEffect, useState } from "react";

import { Box, Autocomplete, Chip, Divider, MenuItem, ListSubheader, Paper, TextField } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useTheme } from "@mui/material/styles";

import { Variable } from "@/types";
import { useAudienceContext } from "@/context";
import {toVariableLabel} from "@/utils";

interface VariableSelectionProps {
  variables: Variable[];
  selection: Variable[];
  onSelect: (variables: Variable[]) => void;
  onDelete: (variable: Variable) => void;
}

const VariableSelection: React.FC<VariableSelectionProps> = (
  {
    variables,
    selection,
    onSelect,
    onDelete
  }
) => {

  const theme = useTheme();

  const [typeOptions, setTypeOptions] = useState<string[]>([]);
  const [selectedType, setSelectedType] = useState<string>('');
  const [variableOptions, setVariableOptions] = useState<Variable[]>([]);

  useEffect(() => {
    setTypeOptions(Array.from(new Set(variables.map(v => v.tag_type))));
    setVariableOptions(variables.filter(v => v.tag_type === selectedType));
  }, [variables, selectedType]);

  const handleTypeSelect = (e: React.ChangeEvent<HTMLInputElement>) =>
    setSelectedType(e.target.value);

  const handleVariableSelect = (event: any, variables: Variable[]) => {
    onSelect(variables);
  }

  return (
    <>
      <Grid size={6}>
        <TextField
          fullWidth
          id="type-select"
          label="Data Type"
          variant="outlined"
          color="secondary"
          select
          value={selectedType}
          onChange={handleTypeSelect}
        >
          {typeOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid size={6}>
        <Autocomplete
          id="variable-select"
          multiple
          disableCloseOnSelect
          color="secondary"
          options={variableOptions}
          value={selection}
          onChange={handleVariableSelect}
          groupBy={(option) => option.tag_category}
          getOptionLabel={(option) => option.tag_name}
          renderGroup={(params) => [
            <Box width="100%">
              <Divider
                textAlign="left"
                sx={{ "&::before, &::after": {borderColor: theme.palette.secondary.main} }}
              >
                <ListSubheader
                  key={params.key}
                  disableSticky
                  sx={{
                    textTransform: "uppercase",
                    fontFamily: "cera-pro-bold",
                    color: theme.palette.secondary.main,
                    fontSize: "16px"
                  }}
                >
                  {params.group}
                </ListSubheader>
              </Divider>
            </Box>,
            params.children
          ]}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              label="Variable"
              variant="outlined"
              placeholder="Select Variables"
              color="secondary"
            />
          )}
          renderTags={() => null}
        />
      </Grid>
      <Grid size={12}>
        <Box
          sx={{
            border: 1,
            borderRadius: 1,
            borderColor: theme.palette.divider,
            minHeight: "48px",
            p: 1
          }}
        >
          {selection.map((variable) => (
            <Chip
              key={variable.tag_id}
              size="small"
              color="secondary"
              label={toVariableLabel(variable)}
              onDelete={() => onDelete(variable)}
              sx={{ m: 0.5 }}
            />
          ))}
        </Box>
      </Grid>
    </>

  )
}

export default VariableSelection;
