import React, { ComponentType } from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";

import { LoadingLayout } from "@/components/layouts";

interface ProtectedRouteProps {
  component: ComponentType;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component
}) => {

  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => (
      <LoadingLayout />
    ),
  });
  return <Component />;
};

export default ProtectedRoute;
