import React from "react";

import { Skeleton } from "@mui/material";
import Grid from "@mui/material/Grid2";


export const AudienceSkeleton: React.FC = () => {

  return (
    <Grid container spacing={2}>
      {Array(8).fill(0).map((_, index) => (
        <Grid key={index}>
          <Skeleton
            variant="rounded"
            width={220}
            height={188}
          />
        </Grid>
      ))}
    </Grid>
  )

}
