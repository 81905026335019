import {
  AdsClick,
  ConnectedTv,
  ContactMail,
  Facebook,
  Google,
  Group,
  Groups,
  Numbers,
  Output,
  Percent,
  Person,
  Place,
  Public,
  Mail,
  Scale,
  ViewArray,
  X,
} from "@mui/icons-material";

export const audienceSortOptions = [
  { name: "default", label: "Created Date" },
  { name: "name", label: "Audience Name" },
];

export const audienceFilterOptions = [
  { name: "build", label: "Build" },
  { name: "upload", label: "Upload" },
];

export const defaultSegment = {segment_id: 0, segment_name: ''}


export const operators = ['AND', 'OR']
export const buildModes = ['broad', 'balanced', 'narrow']
export const modeThreshold: {[key: string]: number} = { 'broad': 4, 'balanced': 6, 'narrow': 8 }
export const scoreTypeMap: {[name: string]: string} = { 'score': 'Index', 'rate': 'Penetration' }


export const demographics = ['Age Band', 'Income Band', 'Lifestage']

export const insightSortOptions = [
  {
    value: 'score',
    label: 'Index',
    icon: <Numbers />
  },
  {
    value: 'rate',
    label: 'Penetration',
    icon: <Percent />
  },
  {
    value: 'score_wt',
    label: 'Weighted Index',
    icon: <Scale />
  },
  {
    value: 'score_rgn',
    label: 'Region Index',
    icon: <Public />
  }
]

export const insightLevelOptions = [
  {
    value: 3,
    label: 'Local',
    description: 'Local: These brands have a really small reach that tends not to extend beyond the local area',
    short: 'Loc.',
    icon: <Person />
  },
  {
    value: 2,
    label: 'Regional',
    description: 'Regional: These brands may be well known, but due to the nature of their business tend to still only attract customers who live nearby',
    short: 'Reg.',
    icon: <Group />
  },
  {
    value: 1,
    label: 'National',
    description: 'National: These will be the most well known and popular brands that have a nationwide presence',
    short: 'Nat.',
    icon: <Groups />
  },
]


export const exportOptions = [
  {
    name: 'postcode',
    label: 'Postcode',
    type: 'Geo',
    description: 'The best Postcodes to reach your audience',
    icon: <Place />
  },
  {
    name: 'sector',
    label: 'Sector',
    type: 'Geo',
    description: 'The best Postal Sectors to reach your audience',
    icon: <Place />
  },
  {
    name: 'door_drop',
    label: 'Door Drop',
    type: 'Mail',
    description: 'Optimise your Door Drop campaigns using Postal Sectors',
    icon: <Mail />
  },
  {
    name: 'partially_addressed',
    label: 'Partially Addressed',
    type: 'Mail',
    description: 'Target the right Postcodes for Partially Addressed Mail ',
    icon: <ContactMail />
  },
  {
    name: 'ooh',
    label: 'OOH',
    type: 'OOH',
    description: 'Integrate with (D)OOH using Postcode Sectors',
    icon: <ViewArray />
  },
  {
    name: 'addressable_tv',
    label: 'Addressable TV',
    type: 'TV',
    description: 'Reach the right households for your Addressable TV campaigns using Postal Sectors',
    icon: <ConnectedTv />
  },
  {
    name: 'geo_onboarding',
    label: 'Geo Onboarding',
    type: 'Digital',
    description: 'Upload Postcodes into Digital Onboarding Environments (e.g. LiveRamp or Amazon Ads)',
    icon: <AdsClick />
  },
  {
    name: 'dv360',
    label: 'DV360',
    type: 'Digital',
    description: 'Upload Latitude and Longitude pairs into DV360',
    icon: <Google />
  },
  {
    name: 'meta',
    label: 'Meta',
    type: 'Social',
    description: 'Add granularity to your campaign in Meta Ads Manager',
    icon: <Facebook />
  },
  {
    name: 'twitter',
    label: 'Twitter',
    type: 'Social',
    description: 'Optimise your Social campaign on X using Postal Sectors',
    icon: <X />
  },
]
