import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { ErrorOutline } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";

import { Audience } from "@/types";
import { deleteAudience } from "@/api";
import { useAudienceContext } from "@/context";



interface DeleteAudienceDialogProps {
  audience: Audience;
  open: boolean;
  onClose: () => void;
  redirect?: string
}

const DeleteAudienceDialog: React.FC<DeleteAudienceDialogProps> = (
  {
    audience,
    open,
    onClose,
    redirect
  }
) => {

  const { getAccessTokenSilently } = useAuth0();
  const theme = useTheme();
  const { dispatch } = useAudienceContext();
  const navigate = useNavigate();

  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const handleDelete = async () => {
    setIsDeleting(true);
    const token = await getAccessTokenSilently();

    const { data, error } = await deleteAudience(
      token,
      audience.audience_name,
      audience.audience_id,
      audience.audience_type,
    );

    if (data) {
      dispatch({ type: "DELETE_AUDIENCE", payload: data });
      setIsDeleting(false);
      redirect ? navigate(redirect) : onClose();
    }
    if (error) {
      dispatch({ type: "ERROR", payload: error.message });
    }
  };

  return (
    <Dialog
      open={open}
      disableEnforceFocus
      aria-labelledby="delete-dialog"
    >
      <DialogTitle
        sx={{ background: theme.palette.error.dark }}
      >
        <Box display="flex" flexDirection="row" alignItems="center">
          <ErrorOutline sx={{ mr: 1 }} />
          <Typography variant="body1">
            Are you sure you want to delete this audience?
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ mt: 2 }}>
        <Typography>
          Please confirm you wish to delete <b>{audience.audience_name}</b> from your
          saved audiences.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="error" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={handleDelete}
          loading={isDeleting}
        >
          Delete Audience
        </Button>
      </DialogActions>
    </Dialog>
  )

}

export default DeleteAudienceDialog;
