import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import { getAudience, getAudiences } from "@/api";
import { useAudienceContext } from "@/context";
import { defaultAudience } from "@/utils";


export const useAudience = (audienceId: string | undefined) => {
  const { state, dispatch } = useAudienceContext();
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {

    const fetchAudience = async (id: string) => {
      dispatch({ type: "LOADING" });
      try {
        const accessToken = await getAccessTokenSilently();
        const { data, error } = await getAudience(accessToken, id);
        if (data) {
          dispatch({ type: "GET_AUDIENCE", payload: data });
        } else {
          dispatch({ type: "ERROR", payload: error?.message || "" });
        }
      } catch (error: any) {
        dispatch({ type: "ERROR", payload: error.message });
      }
    }

    if (!audienceId) {
      dispatch({ type: "GET_AUDIENCE", payload: defaultAudience });
    } else if ( (!state.current || state.current.audience_id !== audienceId) ) {
      fetchAudience(audienceId);
    }
  }, [])

  return {
    audience: state.current,
    loading: state.loading,
    error: state.error
  }
}


export const useAudiences = (audienceType: string) => {
  const { state, dispatch } = useAudienceContext();
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {

    const fetchAudiences = async () => {
      dispatch({ type: "LOADING" });
      try {
        const accessToken = await getAccessTokenSilently();
        const { data, error } = await getAudiences(accessToken, audienceType);
        if (data) {
          dispatch({ type: "GET_AUDIENCE_LIST", payload: data });
        } else {
          dispatch({ type: "ERROR", payload: error?.message || "" });
        }
      } catch (error: any) {
        dispatch({ type: "ERROR", payload: error.message });
      }
    }


    fetchAudiences();
    // if (!state.audiences) {
    //   fetchAudiences();
    // }
  }, [audienceType])

  return {
    audiences: state.audiences,
    loading: state.loading,
    error: state.error
  }
}
