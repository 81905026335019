import { AxiosRequestConfig } from "axios";

import { ApiError, Audience, Insight } from "@/types";
import { fetchData } from "@/utils";

type GetInsightApiResponse = {
  data: { audience: Audience, insight: Insight };
  error: ApiError | null;
};

export const getInsight = async (
  accessToken: string,
  audience_id: string,
  rebuild: boolean,
): Promise<GetInsightApiResponse> => {
  let url = `${process.env.REACT_APP_API_URL}/v2/discover?audience_id=${audience_id}&rebuild=${rebuild}`;

  const config: AxiosRequestConfig = {
    method: "GET",
    url,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return fetchData(config);
};

export const getDiscoverInsight = async (
  accessToken: string,
  mindset_id: string,
): Promise<GetInsightApiResponse> => {
  let url = `${process.env.REACT_APP_API_URL}/v2/discover/mindsets?mindset_id=${mindset_id}`;

  const config: AxiosRequestConfig = {
    method: "GET",
    url,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return fetchData(config);
};

