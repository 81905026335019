import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

import {
  Button,
  Stack,
  Typography
} from '@mui/material';
import Grid from "@mui/material/Grid2";

import {
  Add,
  Person,
  RocketLaunch,
  UploadFile
} from '@mui/icons-material';

import { MenuCard } from "@/components/molecules";
import { PageContainer } from "@/components/containers";

const HomePage: React.FC = () => {
  const navigate = useNavigate();

  const actionButtons = (
    <Stack direction="row" spacing={2}>
      <Button
        variant="contained"
        size="small"
        disableElevation
        startIcon={<Add />}
      >
        New Audience
      </Button>
    </Stack>
  )

  return (
    <PageContainer actions={actionButtons}>
      <Grid
        container
        spacing={2}
        display="flex"
        justifyContent="center"
        // alignItems="center"
        // height="calc(100vh - 64px - 48px - 32px)"
      >
        <Grid size={12}>
          <Typography
            variant="h1"
            sx={{
              textAlign: "center",
              fontFamily: 'cera-pro-bold'
            }}
          >
            —CONNECT—
          </Typography>
        </Grid>
        <Grid>
          <MenuCard
            title="Build an Audience"
            buttonText="New Audience"
            text={`
              Create custom, self-serve audiences by combining attributes from multiple data sources to fit your 
              campaign brief and target the geos to best reach them
            `}
            onClick={() => navigate("audience")}
            icon={<Person color="primary"/>}
          />
        </Grid>
        <Grid>
          <MenuCard
            title="Upload Postcodes"
            buttonText="New Upload"
            text={`
              Match your 1st party data to understand your customer segments or upload store/site locations to 
              understand their local catchment
            `}
            onClick={() => navigate("upload")}
            icon={<UploadFile color="primary"/>}
            disabled={true}
          />
        </Grid>
        <Grid>
          <MenuCard
            title="Activate Geos"
            buttonText="New Activation"
            text={`
              Export geos for targeting or do something
            `}
            onClick={() => navigate("activation")}
            icon={<RocketLaunch color="primary"/>}
            disabled={true}
          />
        </Grid>
      </Grid>
    </PageContainer>
  );
};

export default HomePage;
