import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { sortBy } from "lodash";

import {
  Alert,
  Box,
  Button,
  SelectChangeEvent,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Output } from "@mui/icons-material";


import { InsightSkeleton } from "./InsightSkeleton";
import { BarChartCard, HelpBox, PenPortraitCard, SegmentBox, SegmentCard, useActivePage } from "@/components/atoms";
import { AudienceMiniStats, InsightMap } from "@/components/molecules";
import { PageContainer } from "@/components/containers";
import { defaultSegment, getAudienceBreadcrumb, insightLevelOptions, insightSortOptions} from "@/utils";
import { AudienceSegment, ScoreType } from "@/types";
import { useInsight } from "@/hooks/api";


const InsightPage: React.FC = () => {

  let { audienceId } = useParams<{audienceId: string}>();
  const { audience, insight, loading, error } = useInsight(audienceId);
  const activePage = useActivePage();
  const breadcrumbs = getAudienceBreadcrumb(activePage, audience, loading, 'Insight');
  const navigate = useNavigate();

  const [segment, setSegment] = useState<AudienceSegment>(defaultSegment);
  const [segmentNum, setSegmentNum] = useState<number>(0);

  const [scoreType, setScoreType] = useState<ScoreType>(insightSortOptions[0]);
  const [levels, setLevels] = useState<number[]>(insightLevelOptions.map(x => x.value));

  useEffect(() => {
    audience && audience.segments.length > 0 && setSegment(audience.segments[0])
  }, [audience]);

  useEffect(() => {
    if (audience && audience.segments.length > 0) {
      if (segment.segment_id !== 0) {
        setSegmentNum(audience.segments.map(x => x.segment_id).indexOf(segment.segment_id));
      } else {
        setSegmentNum(0);
      }
    }
  }, [audience, segment]);

  const handleScoreToggle = (event: React.MouseEvent<HTMLElement>, value: string | null) => {
    const newScoreType: ScoreType | undefined = insightSortOptions.find(option => option.value === value);
    value && newScoreType && setScoreType(newScoreType);
  }

  const handleSegmentSelect = (event: SelectChangeEvent) => {
    setSegment( audience?.segments.find((option) => option.segment_name === event.target.value) || defaultSegment);
  };

  const handleLevelToggle = (event: React.MouseEvent<HTMLElement>, values: number[]) => {
    if (!values.includes(1)) {
      setLevels([...values]);
    } else {
      setLevels(values);
    }
  }

  const toLevelLabel = () => {
    if (levels.length === 1) {
      return insightLevelOptions.filter(x => x.value === levels[0])[0].label
    } else if (levels.length === 2) {
      return insightLevelOptions.filter(x => levels.includes(x.value)).map(x => x.short).join(' + ')
    } else {
      return 'All'
    }
  }

  const actionButtons = (
    <Stack direction="row" spacing={2}>
      <Button
        variant="contained"
        size="small"
        disableElevation
        startIcon={<Output />}
        onClick={() => navigate('../export', { relative: 'path' })}
      >
        Export
      </Button>
    </Stack>
  )

  return (
    <PageContainer breadcrumbs={breadcrumbs} actions={actionButtons} scope="insight">
      {loading ?
        <InsightSkeleton />
      :
        <Grid container spacing={2}>
          {error &&
            <Grid>
              <Alert variant="filled" severity="error">
                {error}
              </Alert>
            </Grid>
          }
          {audience && (
            <>
              <Grid size={12} display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" mb={1}>
                <Stack spacing={0.5}>
                  <Typography variant="h6">{audience.audience_name}</Typography>
                  {audience.segments.length > 0 &&
                    <SegmentBox
                      label={'Segment'}
                      value={segment.segment_name}
                      options={audience.segments}
                      onChange={handleSegmentSelect}
                      showSelected={true}
                    />
                  }
                </Stack>
                {audience.audience_size &&
                  <AudienceMiniStats audienceSize={audience.audience_size[segmentNum]} />
                }
              </Grid>
              <Grid size={12} display="flex" justifyContent="space-between" alignItems="flex-end">
                <Typography color="secondary">Audience Snapshot</Typography>
                <Stack direction="row" spacing={2}>
                <Stack>
                  <Box display="flex" flexDirection="row" justifyContent="flex-end">
                    <Typography variant="body2" sx={{ mr: 1 }}>Score:</Typography>
                    <Typography variant="body2" color="secondary">{scoreType.label}</Typography>
                  </Box>
                  <ToggleButtonGroup exclusive size="small" value={scoreType.value} onChange={handleScoreToggle}>
                    {insightSortOptions.map((option, index) => (
                      <HelpBox key={`score-toggle-${index}`} title={option.label}>
                        <ToggleButton value={option.value}>
                          {option.icon}
                        </ToggleButton>
                      </HelpBox>
                    ))}
                  </ToggleButtonGroup>
                </Stack>
                  <Stack>
                    <Box display="flex" flexDirection="row" justifyContent="flex-end">
                      <Typography variant="body2" sx={{ mr: 1 }}>Level:</Typography>
                      <Typography variant="body2" color="secondary">{toLevelLabel()}</Typography>
                    </Box>
                    <ToggleButtonGroup size="small" value={levels} onChange={handleLevelToggle}>
                      {insightLevelOptions.map((option, index) => (
                        <HelpBox key={`level-toggle-${index}`} title={option.label}>
                          <ToggleButton value={option.value}>
                            {option.icon}
                          </ToggleButton>
                        </HelpBox>
                      ))}
                    </ToggleButtonGroup>
                  </Stack>
                </Stack>
              </Grid>
              {/*<Grid size={12}>*/}
              {/*  <QuerySummary varGroups={audience.var_groups} operator={audience.operator}/>*/}
              {/*</Grid>*/}
            </>
          )}
          {insight && (
            <>
              <Grid size={12}>
                <InsightMap scores={insight.locations[segmentNum]} />
              </Grid>
              <Grid size={{ xs: 6, lg: 4, xl: 4}}>
                <BarChartCard
                  title={'Age Band'}
                  variables={sortBy(insight.insights[segmentNum].filter(v => v.tag_category === 'Age Band'), 'rank')}
                  score={scoreType.value}
                />
              </Grid>
              <Grid size={{ xs: 6, lg: 4, xl: 4}}>
                <BarChartCard
                  title={'Income Band'}
                  variables={sortBy(insight.insights[segmentNum].filter(v => v.tag_category === 'Income Band'), 'rank')}
                  score={scoreType.value}
                />
              </Grid>
              <Grid size={{ xs: 6, lg: 4, xl: 4}}>
                <BarChartCard
                  title={'Lifestage'}
                  variables={sortBy(insight.insights[segmentNum].filter(v => v.tag_category === 'Lifestage'), 'rank')}
                  score={scoreType.value}
                />
              </Grid>
            </>
          )}
          {insight && insight.pen_portraits[segmentNum].sections.map((section, index) => {
            const variables = insight.pen_portraits[segmentNum].insight.filter(v => v.section === section.title)[0].variables;
            return (
              <PenPortraitCard
                key={`pen-portrait-${index}`}
                title={section.title}
                variables={variables}
                score={scoreType.value}
                levels={levels}
              />
            )}
          )}
          {insight && insight.mindsets.length > 0 && (
            <Grid size={{ xs: 6, lg: 4, xl: 4}}>
              <SegmentCard title="In Person Experiences" variables={insight.mindsets[segmentNum]} />
            </Grid>
          )}
        </Grid>
      }
    </PageContainer>

  );
}

export default InsightPage;
