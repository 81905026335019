import React, { useState, useCallback, useRef } from "react";
import { Outlet } from "react-router-dom";

import {
  AppBar,
  Box,
  Drawer,
  IconButton,
  Stack,
  Toolbar
} from "@mui/material";
import {
  Menu,
  MenuOpen,
} from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";

import { Logo, LogoDark } from "@/assets/logos";
import { HelpBox } from "@/components/atoms";
import { NavigationMenu, UserMenu } from "@/components/molecules";
import { NavigationContext } from "@/context";
import { getDrawerTransition } from "@/utils";


const BaseLayout: React.FC = () => {

  const theme = useTheme();
  const mode = theme.palette.mode;

  const navigation = React.useContext(NavigationContext)

  const [isMenuExpanded, setIsMenuExpanded] = useState(true);

  const toggleMenuExpanded = useCallback(() => {
    setIsMenuExpanded(!isMenuExpanded);
  }, [isMenuExpanded, setIsMenuExpanded]);

  const handleNavigationSelect = useCallback(() => {
    selectedItemIdRef.current = '';
  }, []);

  const getMenuIcon = useCallback(
    (isExpanded: boolean) => {
      return (
        <HelpBox title={isExpanded ? 'Collapse Menu' : 'Expand Menu'}>
          <IconButton onClick={toggleMenuExpanded}>
            {isExpanded ? <MenuOpen /> : <Menu />}
          </IconButton>
        </HelpBox>
      )
  }, [toggleMenuExpanded]);


  const getDrawerStyle = useCallback(() => {

      const drawerWidth = isMenuExpanded ? 240 : 64;

      return {
        display: 'block',
        flexShrink: 0,
        width: drawerWidth,
        ...getDrawerTransition(isMenuExpanded, 'width'),
        overflowX: 'hidden',
        [`& .MuiDrawer-paper`]: {
          position: 'absolute',
          width: drawerWidth,
          boxSizing: 'border-box',
          ...getDrawerTransition(isMenuExpanded, 'width'),
          overflowX: 'hidden',
        },
      }
    },
    [isMenuExpanded]
  );

  const layoutRef = useRef<Element | null>(null);
  const selectedItemIdRef = useRef('');

  return (
    <Box
      ref={layoutRef}
      sx={{
        position: 'relative',
        display: 'flex',
        overflow: 'hidden',
        height: '100vh',
        width: '100vw',
        minWidth: isMenuExpanded ? 'calc(1028px - 240px)' : 'calc(1028px - 64px)'
      }}

    >
      <AppBar
        color="inherit"
        position="absolute"
        sx={{ zIndex: theme.zIndex.drawer + 1 }}
      >
        <Toolbar variant="dense" sx={{ mx: -1 }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              flexWrap: 'wrap',
              width: '100%',
            }}
          >
            <Stack direction="row" spacing={2} >
              {navigation && getMenuIcon(isMenuExpanded)}
              <Box sx={{ flexGrow: 0, width: "148px", height: "32px", mr: 2 }}>
                {mode === "dark" ? <Logo /> : <LogoDark />}
              </Box>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={1} sx={{ marginLeft: 'auto' }}>
              <UserMenu />
            </Stack>
          </Stack>
        </Toolbar>
      </AppBar>
      {navigation &&
        <Drawer
          variant="permanent"
          PaperProps={{ elevation: 2 }}
          sx={{
            ...getDrawerStyle()
          }}
        >
          <Toolbar variant="dense" />
          <Box
            component="nav"
            sx={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              overflow: 'auto',
              pt: navigation[0]?.kind === 'header' && isMenuExpanded ? 0 : 2,
              ...getDrawerTransition(isMenuExpanded, 'padding'),
            }}
          >
            <NavigationMenu
              navigation={navigation}
              isExpanded={isMenuExpanded}
              onSelect={handleNavigationSelect}
              selectedItemId={selectedItemIdRef.current}
            />
          </Box>
        </Drawer>
      }
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
        }}
      >
        <Toolbar variant="dense" sx={{ displayPrint: 'none' }} />
        <Box
          component="main"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            overflow: 'hidden',
            width: '100%',
            height: '100vh',
            minWidth: isMenuExpanded ? 'calc(1028px - 240px)' : 'calc(1028px - 64px)'
          }}
        >
          <Outlet />
        </Box>
      </Box>

    </Box>
  );
}

export default BaseLayout;
