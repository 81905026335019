import { Variable } from "@/types";

export const abbreviateNumber = (value: number) => {
  const SI_SYMBOL = ["", "k", "M", "B", "T", "P", "E"];
  const tier = (Math.log10(Math.abs(value)) / 3) | 0;
  if (tier === 0) return value;
  const suffix = SI_SYMBOL[tier];
  const scale = Math.pow(10, tier * 3);
  const scaled = value / scale;
  return scaled.toFixed(1) + suffix;
};

export const reformatDate = (date: string | undefined) => {
  const dateObj = new Date(date || "");
  const day = dateObj.getDate();
  const month = dateObj.toLocaleString("default", { month: "short" });
  const year = dateObj.getFullYear();
  return `${day} ${month} ${year}`;
};

export const toVariableLabel = (variable: Variable) => {
  if (variable.tag_type === 'Demographics') {
    return `${variable.tag_group} | ${variable.tag_name}`
  } else {
    return `${variable.tag_type} | ${variable.tag_name}`
  }
}

export const toChartLabel = (label: string) => {
  label = label.replace(/\(.+\)/g, "")
  if (label.includes(" - ")) {
    if (label.length < 15) {
      return label.replace(/\s/g, "")
    } else {
      let parts = label.split(" - ");
      return [parts[0] + ' -', ...parts.slice(1)];
    }
  } else if (label.length > 11 && label.includes("-")) {
    let parts = label.split("-");
    return [parts[0] + ' -', ...parts.slice(1)];
  } else if (label.includes("/")) {
    return label.split("/")
  } else if (label.length > 11) {
    return label.split(" ")
  } else {
    return label
  }
}

export const combineWords = (arr: string | string[]) => {
  if (typeof(arr) === 'string') {
    return arr
  } else {
    return arr.reduce((acc: string[], word: string) => {
      if (word.length <= 4) {
        if (acc.length > 0 && acc[acc.length - 1].length <= 4) {
          acc[acc.length - 1] += ' ' + word;
        } else {
          acc.push(word);
        }
      } else {
        acc.push(word);
      }
      return acc
    }, []);
  }
};

export const toRounded = (value: number) => {
  return Math.round(value);
}

export const toIndex = (value: number) => {
  return Number(value.toFixed(2))
}

export const toPercentage = (value: number, digits: number = 1) => {
  return `${(value * 100).toFixed(digits)}%`;
};
