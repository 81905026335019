import React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";

interface ConfirmDialogProps {
  open: boolean;
  message: string;
  onClose: () => void;
  onConfirm: () => void;
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = (
  {
    open,
    message,
    onClose,
    onConfirm
  }
) => {
  return (
    <Dialog
      open={open}
      disableEnforceFocus
      aria-labelledby="confirm-dialog"
    >
      <DialogTitle>
        <Typography>Please Confirm</Typography>
      </DialogTitle>
      <DialogContent sx={{ py: 2 }}>
        <Typography>{message}</Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={() => onConfirm()}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmDialog;
