import React from "react";

import {Box, IconButton, MenuItem, Paper, Stack, TextField, Typography} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Cancel, RestartAlt } from "@mui/icons-material";

import { HelpBox, VariableSelection } from "@/components/atoms";
import { useAudienceContext } from "@/context";
import { Variable } from "@/types";
import { operators, defaultVariableGroup } from "@/utils";
import { useTheme } from "@mui/material/styles";

interface QueryGroupProps {
  id: number;
  variables: Variable[];
}

const QueryGroup: React.FC<QueryGroupProps> = (
  {
    id,
    variables
  }
) => {

  const theme = useTheme();
  const { state: { current }, dispatch } = useAudienceContext()
  const selection = (current && current.var_groups.find(v => v.id === id))?.variables || [];

  const numQueryGroups = current?.var_groups.length || 0;
  const queryGroup = (current && current.var_groups.find(v => v.id === id)) || defaultVariableGroup(id);

  const handleRemoveGroup = () => {
    dispatch({type: "REMOVE_VARIABLE_GROUP", payload: id})
  }

  const handleChangeOperator = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({type: "CHANGE_GROUP_OPERATOR", payload: {operator: event.target.value, id}})
  }

  const handleReset = () => {
    dispatch({type: "RESET_VARIABLE_GROUP", payload: id})
  }

  const handleVariableSelect = (variables: Variable[]) => {
    dispatch({type: 'CHANGE_GROUP_VARIABLES', payload: {id, variables}})
  }

  const handleVariableDelete = (variable: Variable) => {
    const variables = selection.filter(v => v.tag_id !== variable.tag_id)
    dispatch({type: 'CHANGE_GROUP_VARIABLES', payload: {id, variables}})
  }

  return (
    <Grid
      container
      size={12}
      spacing={2}
      // border={1}
      borderRadius={1}
      // borderColor={theme.palette.divider}
      p={2}
      component={Paper}
    >
      <Grid size={12}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="body2">Query Group {id}</Typography>
          <Stack direction="row" spacing={1}>
            <TextField
              size="small"
              color="secondary"
              select
              value={queryGroup.operator}
              onChange={handleChangeOperator}
              sx={{ minWidth: '80px' }}
            >
              {operators.map((operator) => (
                <MenuItem key={operator} value={operator}>
                  {operator}
                </MenuItem>
              ))}
            </TextField>
            <HelpBox title="Reset Query Group">
              <IconButton onClick={handleReset}>
                <RestartAlt />
              </IconButton>
            </HelpBox>
            {numQueryGroups > 1 && (
              <HelpBox title="Remove Query Group">
                <IconButton onClick={handleRemoveGroup}>
                  <Cancel />
                </IconButton>
              </HelpBox>
            )}
          </Stack>
        </Box>
      </Grid>
      <VariableSelection
        variables={variables}
        selection={selection}
        onSelect={handleVariableSelect}
        onDelete={handleVariableDelete}
      />
    </Grid>
  )
}

export default QueryGroup;
