import React from 'react';

import { IconButton, SvgIconProps } from "@mui/material";
import { useTheme } from '@mui/material/styles';

import { HelpBox } from "@/components/atoms";


type ButtonIconProps = {
  onClick: () => void;
  color: 'primary' | 'secondary' | 'info';
  size: string | number;
  Icon: React.ComponentType<SvgIconProps>;
  disabled?: boolean;
  tooltip?: string;
};

const ButtonIcon: React.FC<ButtonIconProps> = (
  {
    onClick,
    color,
    size,
    Icon,
    disabled,
    tooltip
  }
) => {

  const theme = useTheme();

  return (
    <HelpBox title={tooltip} placement='bottom'>
      <IconButton
        color="primary"
        disabled={disabled}
        onClick={onClick}
        sx={{
          color: theme.palette[color].contrastText,
          // backgroundColor: theme.palette[color].main,
          backgroundColor: theme.palette.background.default,
          '&:hover': {
            // backgroundColor: theme.palette[color].dark
            backgroundColor: theme.palette.background.default,
            borderColor: theme.palette[color].main,
          },
          borderRadius: '4px',
          border: 1,
          borderColor: theme.palette.divider,
          boxShadow: theme.shadows[2],
          height: size,
          width: size,
        }}
      >
        <Icon fontSize='small' color="info"/>
      </IconButton>
    </HelpBox>
  )

}

export default ButtonIcon;
