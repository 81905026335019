import React from "react";

import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { HelpBox } from "@/components/atoms";

interface BuildModeSelectProps {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const BuildModeSelect: React.FC<BuildModeSelectProps> = (
  {
    value,
    onChange
  }
) => {

  const theme = useTheme()

  return (
    <Stack
      spacing={1}
      border={1}
      borderRadius={1}
      borderColor={theme.palette.divider}
      p={2}
    >
      <Typography variant="body1">
        Audience Granularity
      </Typography>
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="mode"
          name="mode"
          value={value}
          onChange={onChange}
          row
        >
          <HelpBox title="All over-indexing geographies">
            <FormControlLabel
              value="broad"
              control={<Radio color="secondary" />}
              label="High Reach"
              labelPlacement="top"
              slotProps={{typography: {variant: "body2"} }}
            />
          </HelpBox>
          <HelpBox title="A balance of high scoring and high volume geographies">
            <FormControlLabel
              value="balanced"
              control={<Radio color="secondary" />}
              label="Balanced"
              labelPlacement="top"
              slotProps={{typography: {variant: "body2"} }}
            />
          </HelpBox>
          <HelpBox title="The top scoring geographies for optimized targeting">
            <FormControlLabel
              value="narrow"
              control={<Radio color="secondary" />}
              label="High Scoring"
              labelPlacement="top"
              slotProps={{typography: {variant: "body2"} }}
            />
          </HelpBox>
        </RadioGroup>
      </FormControl>
    </Stack>
  )
}

export default BuildModeSelect;
