import { Audience, Variable } from "@/types";
import {defaultVariableGroup} from "@/utils";

export const addQueryGroup = (audience: Audience): Audience => {
  const newId = Math.max.apply(Math, audience.var_groups.map(v => v.id)) + 1
  const newVarGroups = [...audience.var_groups, defaultVariableGroup(newId)];
  return {
    ...audience,
    var_groups: newVarGroups,
  }
}

export const removeQueryGroup = (audience: Audience, id: number): Audience => {

  const newVarGroups = audience.var_groups
    .filter(v => v.id !== id)
    .map((v, idx) => ({...v, id: idx + 1}));

  return {
    ...audience,
    var_groups: newVarGroups,
  }
}

export const resetQueryGroup = (audience: Audience, id: number): Audience => {
  let newVarGroups = [...audience.var_groups]
  const varGroupIndex = newVarGroups.findIndex(v => v.id === id)
  newVarGroups[varGroupIndex] = defaultVariableGroup(id)
  return {
    ...audience,
    var_groups: newVarGroups
  }
}

export const changeOperator = (audience: Audience, operator: string, id?: number | null) => {

  if (id) {

    let newVarGroups = [...audience.var_groups]
    const varGroupIndex = newVarGroups.findIndex(v => v.id === id)
    newVarGroups[varGroupIndex].operator = operator
    return {
      ...audience,
      var_groups: newVarGroups
    }

  } else {
    return {
      ...audience,
      operator
    }
  }
}

export const changeVariables = (audience: Audience, id: number, variables: Variable[]) => {

  let newVarGroups = [...audience.var_groups]
  const varGroupIndex = newVarGroups.findIndex(v => v.id === id)
  newVarGroups[varGroupIndex].variables = variables
  return {
    ...audience,
    var_groups: newVarGroups
  }
}

export const changeBuildMode = (audience: Audience, build_mode: string) => {

  return {
    ...audience,
    build_mode
  }
}

export const changeName = (audience: Audience, audience_name: string) => {

  return {
    ...audience,
    audience_name
  }
}
