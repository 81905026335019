import React, {createContext, useContext, useReducer} from "react";

import { audienceReducer } from './AudienceReducer';
import { Action, AudienceState, initialState } from "./types";

const AudienceContext = createContext<{
  state: AudienceState;
  dispatch: React.Dispatch<Action>;
}>({
  state: initialState,
  dispatch: () => null,
});

export const AudienceProvider: React.FC<{ children: React.ReactNode }> = (
  {
    children,
  }) => {

  const [state, dispatch] = useReducer(audienceReducer, initialState);

  return (
    <AudienceContext.Provider value={{ state, dispatch }}>
      {children}
    </AudienceContext.Provider>
  );
};

export const useAudienceContext = () => {
  const context = useContext(AudienceContext);
  if (!context) {
    throw new Error(
      "useAudienceContext must be used within an AudienceProvider"
    );
  }
  return context;
};
