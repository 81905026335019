import React, {useEffect, useState} from "react";
import { useAuth0 } from "@auth0/auth0-react";

import { getInsight, getDiscoverInsight } from "@/api";
import { useAudienceContext } from "@/context";
import { Audience } from "@/types";


export const useInsight = (audienceId: string | undefined) => {
  const { state, dispatch } = useAudienceContext();
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {

    const fetchInsight = async (id: string) => {
      dispatch({ type: "LOADING" });
      try {
        const accessToken = await getAccessTokenSilently();
        const { data, error } = await getInsight(accessToken, id, false);
        if (data) {
          dispatch({ type: "CREATE_INSIGHT", payload: {...data} });
        } else {
          dispatch({ type: "ERROR", payload: error?.message || "" });
        }
      } catch (error: any) {
        dispatch({ type: "ERROR", payload: error.message });
      }
    }

    if (audienceId && !(state.insight)) {
      fetchInsight(audienceId);
    }
  }, [])

  return {
    audience: state.current,
    insight: state.insight,
    loading: state.loading,
    error: state.error
  }
}

export const useDiscoverInsight = (mindsetId: string | undefined) => {
  const { state, dispatch } = useAudienceContext();
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {

    const fetchInsight = async (id: string) => {
      dispatch({ type: "LOADING" });
      try {
        const accessToken = await getAccessTokenSilently();
        const { data, error } = await getDiscoverInsight(accessToken, id);
        if (data) {
          dispatch({ type: "CREATE_INSIGHT", payload: {...data} });
        } else {
          dispatch({ type: "ERROR", payload: error?.message || "" });
        }
      } catch (error: any) {
        dispatch({ type: "ERROR", payload: error.message });
      }
    }

    if (mindsetId && !(state.insight)) {
      fetchInsight(mindsetId);
    }
  }, [])

  return {
    audience: state.current,
    insight: state.insight,
    loading: state.loading,
    error: state.error
  }
}
