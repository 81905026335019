import React, {useEffect, useState} from "react";
import { sortBy } from "lodash";

import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  Legend,
  BarElement,
  LinearScale,
  CategoryScale,
  Title,
  Tooltip as ChartTooltip,
} from "chart.js";
import ChartDataLabels from 'chartjs-plugin-datalabels';

import { Box, Card, CardContent, CardHeader } from "@mui/material";
import { useTheme, darken } from "@mui/material/styles";

import { InsightResult } from "@/types";
import { combineWords, toChartLabel, toIndex, toPercentage } from "@/utils";


ChartJS.register(
  BarElement,
  Legend,
  Title,
  ChartTooltip,
  LinearScale,
  CategoryScale,
  ChartDataLabels
);


interface BarChartCardProps {
  title: string;
  variables: InsightResult[];
  score: string;
}

export const BarChartCard: React.FC<BarChartCardProps> = ({title, variables, score}) => {

  const theme = useTheme();

  const [scores, setScores] = useState<number[]>([]);

  const labels = sortBy(variables, 'tag_id').map(x => x.tag_name);
  const minScore = Math.min(...scores);
  const maxScore = Math.max(...scores);
  const chartMin = score === 'score' ? minScore < -0.1 ? undefined : -0.1 : undefined;
  const chartMax = score === 'score' ? maxScore > 0.1 ? undefined : 0.1 : undefined;

  useEffect(() => {
    if (score === 'rate') {
      setScores(sortBy(variables, 'tag_id').map((row) => row[score as keyof InsightResult] as number))
    } else {
      setScores(sortBy(variables, 'tag_id').map((row) => row[score as keyof InsightResult] as number - 1))
    }
  }, [score, variables]);


  return (
    <Card
      sx={{
        border: 1,
        borderRadius: 1,
        borderColor: theme.palette.divider,
        minHeight: "200px"
      }}
    >
      <CardHeader
        title={title}
        slotProps={{ title: {variant: 'body1'} }}
      />
      <CardContent sx={{ pt: 0 }}>
        <Box width="100%" height="118px">
          <Bar
            options={{
              responsive: true,
              maintainAspectRatio: false,
              layout: {
                padding: 0
              },
              scales: {
                y: {
                  min: chartMin,
                  max: chartMax,
                  ticks: {
                    padding: 0,
                    display: false,
                    color: theme.palette.text.secondary,
                    callback: function(value) {
                      return score === 'rate'
                        ? toPercentage(value as number, 0)
                        : Math.round((value as number + 1) * 100) / 100
                    }
                  },
                  grid: {
                    color: function (context) {
                      return context.tick.value === 0 ? theme.palette.text.secondary : darken(theme.palette.text.secondary, 0.5)
                    },
                    lineWidth: function (context) {
                      return context.tick.value === 0 ? 1 : 0
                    }
                  },
                  border: {
                    display: false,
                    color: darken(theme.palette.text.secondary, 0.5)
                  },
                },
                x: {
                  ticks: {
                    color: theme.palette.text.secondary,
                    maxRotation: 0,
                    padding: 0,
                    font: {
                      size: 8
                    }
                  },
                  grid: {
                    display: false,
                    color: darken(theme.palette.text.secondary, 0.5)
                  },
                  border: {
                    display: false,
                    color: darken(theme.palette.text.secondary, 0.5)
                  },
                }
              },
              plugins: {
                legend: {
                  display: false
                },
                tooltip: {
                  displayColors: false,
                  backgroundColor: theme.palette.info.main,
                  titleColor: theme.palette.info.contrastText,
                  bodyColor: theme.palette.info.contrastText,
                  callbacks: {
                    label: function(context) {
                      return score === 'rate'
                        ? toPercentage(context.parsed.y)
                        : toIndex(context.parsed.y + 1).toString()
                    },
                  }
                }
              }
            }}
            data={{
              labels: labels.map(label => combineWords(toChartLabel(label))),
              datasets: [
                {
                  label: 'Audience',
                  data: scores,
                  backgroundColor: theme.palette.secondary.main,
                  borderColor: theme.palette.secondary.dark,
                  borderRadius: 4,
                  datalabels: {
                    color: function(context) {
                      let value = context.dataset.data[context.dataIndex];
                      if (value) {
                        if (value > maxScore - 0.05){
                          return theme.palette.primary.contrastText
                        } else if (minScore < 0 && value < minScore + 0.05) {
                          return theme.palette.primary.contrastText
                        } else {
                          return theme.palette.text.secondary
                        }
                      } else {
                        return theme.palette.text.secondary
                      }
                    },
                    font: {
                      size: 10
                    },
                    anchor: function(context) {
                      let value = context.dataset.data[context.dataIndex];
                      if (value) {
                        return value > 0 ? 'end' : 'start'
                      } else {
                        return 'end'
                      }
                    },
                    align: function(context) {
                      let value = context.dataset.data[context.dataIndex];
                      if (value) {
                        if (value > maxScore - 0.05){
                          return 'start'
                        } else if (value < minScore + 0.05) {
                          return 'end'
                        } else {
                          return value > 0 ? 'end' : 'start'
                        }
                      } else {
                        return 'end'
                      }
                    },
                    offset: 0,
                    clip: false,
                    // clamp: false,
                    formatter: function(value, context) {
                      return score === 'rate' ? toPercentage(value) : toIndex(value + 1);
                    }
                  }
                }
              ]
            }}
          />
        </Box>
      </CardContent>
    </Card>
  )
}

export default BarChartCard;
