import type {Theme} from "@mui/material";


export function getDrawerTransition(isExpanded: boolean, property: string) {
  return {
    transition: (theme: Theme) =>
      theme.transitions.create(property, {
        easing: theme.transitions.easing.sharp,
        duration: isExpanded
          ? theme.transitions.duration.enteringScreen
          : theme.transitions.duration.leavingScreen,
      })
  }
}
