import React, {useEffect, useState} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Stack,
  Typography
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { AddCircle, BarChartOutlined, Construction, Delete, House, Output, PeopleAlt, Place } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";

import { BuildSkeleton } from "./BuildSkeleton";
import { buildAudience, getInsight, saveAudience } from "@/api";
import { BuildModeSelect, HelpBox, HelpText, StatBox, QueryGroup, QueryGroupOperator, useActivePage } from "@/components/atoms";
import { ConfirmDialog, DeleteAudienceDialog, CreateAudienceDialog, AudienceMiniStats } from "@/components/molecules";
import { PageContainer } from "@/components/containers";
import { useAudienceContext } from "@/context";
import { useAudience, useVariables } from "@/hooks";
import { abbreviateNumber, getAudienceBreadcrumb, modeThreshold} from "@/utils";
import { Breadcrumb } from "@/types";


const BuildPage: React.FC = () => {

  const theme = useTheme();
  const { getAccessTokenSilently } = useAuth0();
  const { state, dispatch } = useAudienceContext();
  const navigate = useNavigate();

  let { audienceId } = useParams<{audienceId: string}>();
  audienceId = audienceId === 'new' ? undefined : audienceId;
  const { audience, loading: audienceLoading, error: audienceError } = useAudience(audienceId);
  const numQueryGroups = audience?.var_groups?.length || 0;

  const activePage = useActivePage();
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([]);

  const {variables, loading: variablesLoading, error: variablesError} = useVariables();
  const pageLoading = audienceLoading || variablesLoading;

  const [buildLoading, setBuildLoading] = useState<boolean>(false);
  const [buildError, setBuildError] = useState<string | null >(null);
  const [insightLoading, setInsightLoading] = useState<boolean>(false);
  const [insightError, setInsightError] = useState<string | null >(null);

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);

  useEffect(() => {
    setBreadcrumbs(getAudienceBreadcrumb(activePage, audience, audienceLoading));
  }, [activePage, audience, audienceLoading]);

  useEffect(() => {
    state.change && setBuildError(null);
  }, [state.change])


  const handleAddGroup = () => {
    dispatch({type: 'ADD_VARIABLE_GROUP'});
  }

  const handleChangeOperator = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({type: "CHANGE_OPERATOR", payload: event.target.value})
  }

  const handleChangeMode = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({type: "CHANGE_BUILD_MODE", payload: event.target.value})
  }

  const handleBuildAudience = async () => {
    const token = await getAccessTokenSilently();
    setBuildError(null);
    setBuildLoading(true);

    if (audience) {

      const queries = audience.var_groups
        .filter(
          (group) => group.variables.length > 0
        )
        .map(
          (group) => {
            const ids = group.variables.map(v => v.tag_id).join(',');
            return `${group.operator}(${ids})`;
          }
        )

      const { data, error } = await buildAudience(
        token,
        queries,
        modeThreshold[audience.build_mode],
        audience.operator,
        audienceId
      )

      if (data) {
        if (data.audience_size[0].num_postcodes > 0) {
          dispatch({type: "BUILD_AUDIENCE", payload: {audience_id: data.audience_id, audience_size: data.audience_size}})
        } else {
          setBuildError('Unable to build audience or the audience size was 0, please adjust your criteria and try again')
        }
      }

      if (error) {
        setBuildError(error.message)
      }
    }

    setBuildLoading(false);

  }

  const handleAudienceInsight = () => {

    if (audience) {

      if (audience.audience_id && audience.audience_id !== '') {

        // Existing audience - if change show warning and rebuild otherwise go to insights
        if (state.change) {
          setConfirmDialogOpen(true);
        } else {
          navigate("insight");
        }

      } else {

        // New audience - show save audience dialog then go to insights
        setCreateDialogOpen(true);

      }
    }
  }

  const handleAudienceExport = () => {
    navigate("export");
  }

  const handleUpdateAudience = async (audience_name?: string) => {
    const token = await getAccessTokenSilently();
    setInsightError(null);
    setInsightLoading(true);
    setCreateDialogOpen(false);
    setConfirmDialogOpen(false);

    if (audience) {
      let newAudienceName = audience_name ?? audience.audience_name;
      let updatedAudience = {...audience, audience_name: newAudienceName};

      const {data, error} = await saveAudience(token, updatedAudience, true);

      if (data) {
        const newAudience = data.filter(audience => audience.audience_name === updatedAudience.audience_name)[0]
        dispatch({type: 'UPDATE_AUDIENCE', payload: {audiences: data, current: newAudience} });

        const insight = await getInsight(token, newAudience.audience_id, true);

        if (insight.data) {
          dispatch({type: 'CREATE_INSIGHT', payload: { ...insight.data } });
          navigate(`../${newAudience.audience_id}/insight`, {relative: 'path'});
        }

        if (insight.error) {
          setInsightError(insight.error.message);
        }
      }

      if (error) {
        setInsightError(error.message);
      }
    }

    setInsightLoading(false);

  }

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  }

  const handleCloseSaveDialog = () => {
    setCreateDialogOpen(false);
  }

  const handleCloseConfirmDialog = () => {
    setConfirmDialogOpen(false);
  }

  const actionButtons = (
    <Stack direction="row" spacing={2}>
      <Button
        variant="contained"
        size="small"
        color="error"
        disableElevation
        startIcon={<Delete />}
        disabled={pageLoading || buildLoading || insightLoading || audienceId === undefined}
        onClick={() => setDeleteDialogOpen(true)}
      >
        Delete
      </Button>
      <Button
        variant="contained"
        size="small"
        disableElevation
        startIcon={<Construction />}
        loading={buildLoading}
        disabled={(state.built) || (audience?.var_groups[0].variables.length === 0)}
        onClick={handleBuildAudience}
      >
        Build
      </Button>
      {audience && audience.audience_size &&
        <>
          <Button
            variant="contained"
            size="small"
            disableElevation
            startIcon={<BarChartOutlined />}
            loading={insightLoading}
            disabled={!state.built}
            onClick={handleAudienceInsight}
          >
            Insight
          </Button>
          <Button
            variant="contained"
            size="small"
            disableElevation
            startIcon={<Output />}
            disabled={!state.built || insightLoading || buildLoading}
            onClick={handleAudienceExport}
          >
            Export
          </Button>
        </>
      }
    </Stack>
  )

  // TODO handle these error messages better - maybe create different alert components for each error type

  return (
    <PageContainer
      breadcrumbs={audienceError ? undefined : breadcrumbs}
      actions={audienceError ? undefined : actionButtons}
      loading={buildLoading || insightLoading}
      loadingText={buildLoading ? 'Building Audience' : 'Calculating Audience Insight'}
    >
      {pageLoading ?
        <BuildSkeleton />
      :
        <Grid container spacing={2}>
          <Grid container size={12}>
            {variablesError &&
              <Grid>
                <Alert variant="filled" severity="error">
                  There was a problem loading the data, please try again and contact the systems team if the problem persists
                </Alert>
              </Grid>
            }
            {audienceError &&
              <Grid>
                <Alert variant="filled" severity="error">
                  Unable to load audience, please check that the audience id is correct and try again
                </Alert>
              </Grid>
            }
            {buildError &&
              <Grid>
                <Alert variant="filled" severity="error">
                  Unable to build audience, please check that audience criteria is correct and try again
                </Alert>
              </Grid>
            }
            {insightError &&
              <Grid>
                <Alert variant="filled" severity="error">
                  Unable to calculate insight, please rebuild the audience and try again
                </Alert>
              </Grid>
            }
          </Grid>
          {audience && variables && (
            <>
              <Grid size={12} display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" mb={1}>
                <Typography variant="h6">{audience.audience_name || "New Audience"}</Typography>
                {audience.audience_size &&
                  <AudienceMiniStats audienceSize={audience.audience_size[0]} />
                }
              </Grid>
              <Grid size={12}>
                <HelpText
                  text="Audience Query Groups"
                  helpText="
                  A query group is a set of variables that are combined using a boolean operator (e.g. AND/OR) to define an
                  audience behaviour
                  "
                  variant="body1"
                />
              </Grid>
              {audience.var_groups.map((group, index) => (
                <React.Fragment key={index}>
                  <QueryGroup id={group.id} variables={variables}/>
                  {numQueryGroups > 1 && index + 1 !== numQueryGroups && (
                    <Grid size={12}>
                      <QueryGroupOperator value={audience.operator} onChange={handleChangeOperator} />
                    </Grid>
                  )}
                </React.Fragment>
              ))}
              <Grid size={12}>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  border={1}
                  borderRadius={1}
                  borderColor={theme.palette.divider}
                  p={1}
                >
                  <HelpBox title="Add Query Group">
                    <IconButton size="small" onClick={handleAddGroup}>
                      <AddCircle color="secondary"/>
                    </IconButton>
                  </HelpBox>
                </Box>
              </Grid>
              <Grid size={12} mt={2}>
                <HelpText
                  text="Audience Settings"
                  helpText="Fine tune your audience by defining how targetted you want to be"
                  variant="body1"
                />
              </Grid>
              <Grid>
                <BuildModeSelect value={audience.build_mode} onChange={handleChangeMode} />
              </Grid>
              {audience.audience_size &&
                <>
                  <Grid size={12} mt={2}>
                    <HelpText
                      text="Audience Size"
                      helpText="An estimate of how many postcodes your audience will cover"
                      variant="body1"
                    />
                  </Grid>
                  <Grid>
                    <StatBox
                      name="Postcodes"
                      value={audience.audience_size[0].num_postcodes}
                      valueFormatter={abbreviateNumber}
                      icon={<Place fontSize="small" color="secondary"/>}
                    />
                  </Grid>
                  <Grid>
                    <StatBox
                      name="Households"
                      value={audience.audience_size[0].num_households}
                      valueFormatter={abbreviateNumber}
                      icon={<House fontSize="small" color="secondary"/>}
                    />
                  </Grid>
                  <Grid>
                    <StatBox
                      name="Population"
                      value={audience.audience_size[0].population_count}
                      valueFormatter={abbreviateNumber}
                      icon={<PeopleAlt fontSize="small" color="secondary"/>}
                    />
                  </Grid>
                </>
              }
              <DeleteAudienceDialog
                audience={audience}
                open={deleteDialogOpen}
                onClose={handleCloseDeleteDialog}
                redirect="/audience"
              />
              <CreateAudienceDialog
                open={createDialogOpen}
                onClose={handleCloseSaveDialog}
                onConfirm={handleUpdateAudience}
              />
              <ConfirmDialog
                open={confirmDialogOpen}
                message="
                Proceeding will mean that your saved audience will get overwritten.
                Create a new audience if you don't want this to happen.
                "
                onClose={handleCloseConfirmDialog}
                onConfirm={handleUpdateAudience}
              />
            </>
          )}
        </Grid>
      }
    </PageContainer>
  );
}

export default BuildPage;
