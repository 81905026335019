import React from "react";

import {
  BarChartOutlined,
  Delete,
  Download,
  House,
  PeopleAlt,
  PieChart,
  Place,
} from "@mui/icons-material";
import {
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  IconButton,
  Typography,
} from "@mui/material";

import { HelpBox } from "@/components/atoms"
import { Audience } from "@/types";

import { abbreviateNumber, reformatDate } from "@/utils";

interface AudienceCardProps {
  audience: Audience;
  onEdit: (audience: Audience) => void;
  onInsights?: (audience: Audience) => void;
  onDelete?: (audience: Audience) => void;
}

const AudienceCard: React.FC<AudienceCardProps> = (
  {
    audience,
    onEdit,
    onInsights,
    onDelete,
  }
) => {

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        width: "220px",
      }}
    >
      <CardActionArea
        onClick={() => onEdit(audience)}
      >
        {/*<CardHeader*/}
        {/*  action={*/}
        {/*    <Box>*/}
        {/*      <Chip*/}
        {/*        variant="filled"*/}
        {/*        size="small"*/}
        {/*        label={audience.audience_type}*/}
        {/*      />*/}
        {/*    </Box>*/}
        {/*  }*/}
        {/*/>*/}
        <CardContent
          sx={{
            height: 140,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h6"
            textAlign="center"
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: "2",
              WebkitBoxOrient: "vertical",
            }}
          >
            {audience.audience_name}
          </Typography>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-evenly"
            width="100%"
            mt={2}
          >
            {audience.audience_size && audience.audience_size.length > 1 ? (
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-evenly"
              >
                <PieChart color="primary" sx={{ mr: 1 }} />
                <Typography variant="body1" fontWeight={600}>
                  {audience.audience_size.length} segments
                </Typography>
              </Box>
            ) : (
              <>
                {audience.audience_size && (
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <Place fontSize="small" sx={{ mr: 1 }} />
                    <Typography variant="body2">
                      {abbreviateNumber(
                        audience.audience_size[0].num_postcodes
                      )}
                    </Typography>
                  </Box>
                )}
                {audience.audience_size &&
                  audience.audience_size[0].population_count && (
                    <Box display="flex" flexDirection="row" alignItems="center">
                      <PeopleAlt fontSize="small" sx={{ mr: 1 }} />
                      <Typography variant="body2" >
                        {abbreviateNumber(
                          audience.audience_size[0].population_count
                        )}
                      </Typography>
                    </Box>
                )}
              </>
            )}
          </Box>
        </CardContent>
      </CardActionArea>
      <CardActions
        sx={{
          display: "flex",
          justifyContent: "space-between",
          pl: 2,
        }}
      >
        <Box>
          {audience.created_at && (
            <Typography
              component="div"
              variant="body2"
              color={"text.secondary"}
              mt={1}
            >
              {reformatDate(audience.created_at)}
            </Typography>
          )}
        </Box>
        <Box>
          {onInsights &&
            <HelpBox title={"View insight"}>
              <IconButton
                onClick={() => onInsights(audience)}
                sx={{ p: 0.5, borderRadius: "6px" }}
              >
                <BarChartOutlined color="primary" />
              </IconButton>
            </HelpBox>
          }
          {/*<HelpBox title={"Export audience"}>*/}
          {/*  <IconButton*/}
          {/*    // onClick={() => onExport(audience.audience_id, audience.audience_type)}*/}
          {/*    sx={{ p: 0.5, borderRadius: "6px" }}*/}
          {/*  >*/}
          {/*    <Download color="primary" />*/}
          {/*  </IconButton>*/}
          {/*</HelpBox>*/}
          {onDelete &&
          <HelpBox title={"Delete audience"}>
            <IconButton
              onClick={() => onDelete(audience)}
              sx={{ p: 0.5, borderRadius: "6px" }}
            >
              <Delete color="primary" />
            </IconButton>
          </HelpBox>
          }
        </Box>
      </CardActions>
    </Card>
  );
};

export default AudienceCard;
