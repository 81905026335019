import React from "react";

import { Stack } from "@mui/material";
import { House, PeopleAlt, Place } from "@mui/icons-material";

import { MiniStatBox } from "@/components/atoms";
import { AudienceSummary } from "@/types";
import { abbreviateNumber } from "@/utils";

interface AudienceMiniStatsProps {
  audienceSize: AudienceSummary;
}

export const AudienceMiniStats: React.FC<AudienceMiniStatsProps> = (
  {
    audienceSize,
  }) => {

  return (
    <Stack direction="row" spacing={2}>
      <MiniStatBox
        name="Postcodes"
        value={audienceSize.num_postcodes}
        valueFormatter={abbreviateNumber}
        icon={<Place fontSize="small" color="secondary"/>}
      />
      <MiniStatBox
        name="Households"
        value={audienceSize.num_households}
        valueFormatter={abbreviateNumber}
        icon={<House fontSize="small" color="secondary"/>}
      />
      <MiniStatBox
        name="Population"
        value={audienceSize.population_count}
        valueFormatter={abbreviateNumber}
        icon={<PeopleAlt fontSize="small" color="secondary"/>}
      />
    </Stack>
  )
}

export default AudienceMiniStats;
