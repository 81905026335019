import React, { useState, useCallback, useMemo, useEffect } from "react";

import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import Map, {
  AttributionControl,
  Layer,
  MapboxGeoJSONFeature,
  MapEvent,
  MapLayerMouseEvent,
  MapStyleDataEvent,
  Source,
  ViewStateChangeEvent
} from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";

import { LocationResult } from "@/types";
import { extractBreaks, getFillColor } from "@/utils";

import { lightMap, darkMap } from "./config";
import {
  sectorBaseLayer,
  sectorHoverLayer,
  sectorSelectLayer,
  districtBaseLayer,
  districtHoverLayer,
  districtSelectLayer,
  areaBaseLayer,
  areaHoverLayer,
  areaSelectLayer,
} from "./layers";
import { Overlay } from "./InsightMapOverlay";


type InsightMapProps = {
  scores: LocationResult;
};

const InsightMap: React.FC<InsightMapProps> = (
  {
    scores
  }
) => {

  const theme = useTheme();
  const mapStyle = theme.palette.mode === 'light' ? lightMap : darkMap;

  const [selected, setSelected] = useState<MapboxGeoJSONFeature | undefined>(undefined);
  const [hovered, setHovered] = useState<number | string | undefined>(0);
  const [cursor, setCursor] = useState<string>("auto");
  const [breaks, setBreaks] = useState<number[]>([]);
  const [firstTime, setFirstTime] = useState<boolean>(true);

  const onLoad = useCallback((event: MapEvent) => {
    const map = event.target;
    map &&
      scores.area.forEach((value) => {
        map.setFeatureState(
          {
            id: value.gid,
            source: "area-source",
            sourceLayer: "postal_area_test",
          },
          { score: value.rate, name: value.name, level: value.level }
        );
      });
    map && map.setPaintProperty("area-base", "fill-color", getFillColor(extractBreaks(scores.area)));
    map &&
      scores.district.forEach((value) => {
        map.setFeatureState(
          {
            id: value.gid,
            source: "district-source",
            sourceLayer: "postal_district_test",
          },
          { score: value.rate, name: value.name, level: value.level }
        );
      });
    map && map.setPaintProperty("district-base", "fill-color", getFillColor(extractBreaks(scores.district)));
    map &&
      scores.sector.forEach((value) => {
        map.setFeatureState(
          {
            id: value.gid,
            source: "sector-source",
            sourceLayer: "postal_sector_test",
          },
          { score: value.rate, name: value.name, level: value.level }
        );
     });
    map && map.setPaintProperty("sector-base", "fill-color", getFillColor(extractBreaks(scores.sector)));
    setBreaks(extractBreaks(scores.area))
  }, [scores]);

  useEffect(() => {
    setBreaks(extractBreaks(scores.area))
  }, [scores])

  const onClick = useCallback((event: MapLayerMouseEvent) => {
    const feature = event.features && event.features[0];
    if (feature && feature.state.name) {
      setSelected(feature);
    } else {
      clearSelected();
    }
  }, []);

  const onHover = useCallback((event: MapLayerMouseEvent) => {
    const feature = event.features && event.features[0];
    if (feature && feature.state.name) {
      setHovered(feature.id);
    } else {
      setHovered(0);
    }
  }, []);

  const onZoom = useCallback((event: ViewStateChangeEvent) => {
    const zoom = event.viewState.zoom;
    if (zoom > 9) {
      setBreaks(extractBreaks(scores.sector))
    } else if (zoom > 7) {
      setBreaks(extractBreaks(scores.district))
    } else {
      setBreaks(extractBreaks(scores.area))
    }
  }, [scores]);

  const onStyleData = useCallback((event:  MapStyleDataEvent) => {
    setFirstTime(false);
  }, []);

  const clearSelected = () => {
    setSelected(undefined);
  };

  const onMouseEnter = useCallback(() => setCursor("pointer"), []);
  const onMouseLeave = useCallback(() => setCursor("auto"), []);

  const hoverFilter = useMemo(() => ["==", ["id"], hovered], [hovered]);
  const selectFilter = useMemo(
    () => ["==", ["id"], selected ? selected.id : 0],
    [selected]
  );

  return (
    <Box
      height='800px'
      width='100%'
      position="relative"
      sx={{
        overflow: 'hidden',
        border: 1,
        borderRadius: 1,
        borderColor: theme.palette.divider,
        '.mapbox-improve-map': {
          display: 'none'
        },
        '.mapboxgl-ctrl.mapboxgl-ctrl-attrib': {
          backgroundColor: 'transparent !important'
        },
        '.mapboxgl-ctrl-attrib-inner': {
          color: theme.palette.text.secondary
        },
        '.mapboxgl-ctrl-attrib a': {
          color: theme.palette.text.secondary
        }
      }}
    >
      <Map
        initialViewState={{
          longitude: -4,
          latitude: 55,
          zoom: 4.8,
        }}
        projection={{ name: "mercator" }}
        minZoom={4}
        maxBounds={[[-30, 36], [30, 66]]}
        mapStyle={mapStyle}
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_MAP_TOKEN}
        cursor={cursor}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={onClick}
        onLoad={onLoad}
        onMouseMove={onHover}
        onZoomEnd={onZoom}
        onStyleData={onStyleData}
        interactiveLayerIds={["area-base", "district-base", "sector-base"]}
        attributionControl={false}
        touchPitch={false}
        dragRotate={false}

      >
        <AttributionControl customAttribution=" " />
        <Source
          id="area-source"
          type="vector"
          url="mapbox://mpilarczykstarcount.postal_area_test"
        >
          <Layer
            beforeId="waterway-label"
            {...areaBaseLayer}
          />
          <Layer
            beforeId="waterway-label"
            {...areaHoverLayer}
            filter={hoverFilter}
          />
          <Layer
            beforeId="waterway-label"
            {...areaSelectLayer}
            filter={selectFilter}
          />
        </Source>
        <Source
          id="district-source"
          type="vector"
          url="mapbox://mpilarczykstarcount.postal_district_test"
        >
          <Layer
            beforeId="waterway-label"
            {...districtBaseLayer}
          />
          <Layer
            beforeId="waterway-label"
            {...districtHoverLayer}
            filter={hoverFilter}
          />
          <Layer
            beforeId="waterway-label"
            {...districtSelectLayer}
            filter={selectFilter}
          />
        </Source>
        <Source
          id="sector-source"
          type="vector"
          url="mapbox://mpilarczykstarcount.postal_sector_test"
        >
          <Layer
            beforeId="waterway-label"
            {...sectorBaseLayer}
          />
          <Layer
            beforeId="waterway-label"
            {...sectorHoverLayer}
            filter={hoverFilter}
          />
          <Layer
            beforeId="waterway-label"
            {...sectorSelectLayer}
            filter={selectFilter}
          />
        </Source>
        <Overlay
          scores={scores}
          selected={selected}
          clearSelected={clearSelected}
          breaks={breaks}
          firstTime={firstTime}
        />
      </Map>
    </Box>
  )
}

export default InsightMap;
