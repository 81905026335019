import {
  GET_AUDIENCE_LIST,
  GET_AUDIENCE,
  CREATE_AUDIENCE,
  RESET_AUDIENCE_STATUS,
  BUILD_AUDIENCE,
  SAVE_AUDIENCE,
  DELETE_AUDIENCE,
  UPDATE_AUDIENCE,
  CREATE_INSIGHT,
  ADD_VARIABLE_GROUP,
  REMOVE_VARIABLE_GROUP,
  RESET_VARIABLE_GROUP,
  CHANGE_OPERATOR,
  CHANGE_BUILD_MODE,
  CHANGE_GROUP_OPERATOR,
  CHANGE_GROUP_VARIABLES,
  CHANGE_NAME,
  LOADING,
  ERROR,
  RESET,
} from "./AudienceActions";
import { Action, AudienceState } from "./types";
import {
  addQueryGroup,
  changeBuildMode,
  changeName,
  changeOperator,
  changeVariables,
  removeQueryGroup,
  resetQueryGroup
} from "./helpers";


export const audienceReducer = (
  state: AudienceState,
  action: Action
): AudienceState => {

  console.log(action.type);

  switch (action.type) {

    case CREATE_AUDIENCE:
      return {
        ...state,
        current: null,
        insight: null,
        loading: false,
        change: false,
        built: false,
        error: null,
      }

    case GET_AUDIENCE_LIST:
      return {
        ...state,
        audiences: action.payload,
        current: null,
        insight: null,
        loading: false,
        change: false,
        built: false,
        error: null,
      };

    case GET_AUDIENCE:
      return {
        ...state,
        current: action.payload,
        insight: null,
        loading: false,
        change: false,
        built: true,
        error: null,
      };

    case RESET_AUDIENCE_STATUS:
      return {
        ...state,
        loading: false,
        change: false,
        built: false,
        error: null,
      };


    case BUILD_AUDIENCE:

      return {
        ...state,
        current: state.current ? {
          ...state.current,
          audience_id: action.payload.audience_id,
          audience_size: action.payload.audience_size,
        }: state.current,
        insight: null,
        loading: false,
        built: true,
        error: null,
      };

    case CREATE_INSIGHT: {
      return {
        ...state,
        current: action.payload.audience,
        insight: action.payload.insight,
        loading: false,
        change: false,
        error: null,
      };
    }

    case SAVE_AUDIENCE:
      return {
        ...state,
        current: {
          ...state.current,
          audience_id: action.payload.audience_id,
          audience_name: action.payload.audience_name,
          created_at: action.payload.created_at,
          build_mode: action.payload.build_mode,
          operator: action.payload.operator,
          var_groups: action.payload.var_groups,
          segment: action.payload.segment,
          segments: action.payload.segments,
          audience_type: action.payload.audience_type,
          user_id: action.payload.user_id,
          audience_size: action.payload.audience_size,
          export: action.payload.export,
        },
        insight: null,
        loading: false,
        change: false,
        error: null,
      };

    case UPDATE_AUDIENCE: {
      return {
        ...state,
        audiences: action.payload.audiences,
        current: action.payload.current,
        insight: null,
        loading: false,
        change: false,
        error: null,
      };
    }

    case DELETE_AUDIENCE: {
      return {
        ...state,
        change: false,
        audiences: action.payload,
      };
    }

    case RESET:
      return {
        ...state,
        current: null,
        insight: null,
        loading: false,
        change: false,
        built: false,
        error: null,
      };

    case LOADING:
      return {
        ...state,
        loading: true,
        change: false,
        error: null,
      };

    case ERROR:
      return {
        ...state,
        loading: false,
        change: false,
        error: action.payload,
      };

    case ADD_VARIABLE_GROUP:
      return {
        ...state,
        current: state.current ? addQueryGroup(state.current) : state.current,
        change: true,
        built: false,
      }

    case REMOVE_VARIABLE_GROUP:
      return {
        ...state,
        current: state.current ? removeQueryGroup(state.current, action.payload) : state.current,
        change: true,
        built: false,
      }

    case RESET_VARIABLE_GROUP:
      return {
        ...state,
        current: state.current ? resetQueryGroup(state.current, action.payload) : state.current,
        change: true,
        built: false,
      }

    case CHANGE_OPERATOR:
      return {
        ...state,
        current: state.current ? changeOperator(state.current, action.payload) : state.current,
        change: true,
        built: false,
      }

    case CHANGE_BUILD_MODE:
      return {
        ...state,
        current: state.current ? changeBuildMode(state.current, action.payload) : state.current,
        change: true,
        built: false,
      }

    case CHANGE_GROUP_OPERATOR:
      return {
        ...state,
        current: state.current ? changeOperator(state.current, action.payload.operator, action.payload.id) : state.current,
        change: true,
        built: false,
      }

    case CHANGE_GROUP_VARIABLES:
      return {
        ...state,
        current: state.current ? changeVariables(state.current, action.payload.id, action.payload.variables) : state.current,
        change: true,
        built: false,
      }

    case CHANGE_NAME:
      return {
        ...state,
        current: state.current ? changeName(state.current, action.payload) : state.current
      }

    default:
      return state;
  }
};
