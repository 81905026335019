import React from "react";

import {
  Card,
  CardActionArea,
  CardContent,
  Typography,
} from "@mui/material";
import { AddCircle } from "@mui/icons-material";

interface CreateAudienceCardProps {
  onClick: () => void;
}

const CreateAudienceCard: React.FC<CreateAudienceCardProps> = ({onClick}) => {

  return (
    <Card
      variant="outlined"
      sx={{
        background: "transparent",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "220px",
        height: "188px"
      }}
    >
      <CardActionArea
        onClick={onClick}
      >
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "188px"
          }}
        >
          <AddCircle color="primary" sx={{ fontSize: 50 }} />
          <Typography variant="h6" mt={1}>
            Build New Audience
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default CreateAudienceCard;
