import './styles/styles.scss';

import {
  AudiencePage,
  BuildPage,
  CallbackPage,
  DiscoverPage,
  ExportPage,
  HomePage,
  InsightPage,
  PlaceholderPage,
} from '@/pages';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import App from './App';
import { BaseLayout } from '@/components/layouts';
import { ProtectedRoute } from '@/components/containers';
import React from 'react';
import { StyledEngineProvider } from '@mui/material/styles';
import { createRoot } from 'react-dom/client';

const router = createBrowserRouter(
  [
    {
      Component: App,
      children: [
        {
          path: '/',
          // Component: BaseLayout,
          element: <ProtectedRoute component={BaseLayout} />,
          children: [
            {
              path: '',
              Component: HomePage,
            },
            {
              path: 'callback',
              Component: CallbackPage,
            },
            {
              path: 'audience',
              Component: AudiencePage,
            },
            {
              path: 'audience/:audienceId',
              Component: BuildPage,
            },
            {
              path: 'audience/:audienceId/insight',
              Component: InsightPage,
            },
            {
              path: 'audience/:audienceId/export',
              Component: ExportPage,
            },
            {
              path: 'upload',
              Component: PlaceholderPage,
            },
            {
              path: 'activation',
              Component: PlaceholderPage,
            },
            {
              path: 'discover',
              Component: DiscoverPage,
            },
            {
              path: 'discover/:audienceId/insight',
              Component: InsightPage,
            },
            {
              path: 'explore',
              Component: PlaceholderPage,
            },
          ],
        },
      ],
    },
  ],
  {
    future: {
      v7_fetcherPersist: true,
      v7_normalizeFormMethod: true,
      v7_partialHydration: true,
      v7_relativeSplatPath: true,
      v7_skipActionErrorRevalidation: true,
    },
  }
);

const container = document.getElementById('root')! as HTMLElement;
const root = createRoot(container);

// root.render(
//   <React.StrictMode>
//     <StyledEngineProvider injectFirst>
//       <BrowserRouter>
//         <AuthContainer>
//           <App />
//         </AuthContainer>
//       </BrowserRouter>
//     </StyledEngineProvider>
//   </React.StrictMode>
// );

root.render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <RouterProvider router={router} future={{ v7_startTransition: true }} />
    </StyledEngineProvider>
  </React.StrictMode>
);
