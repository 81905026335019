import React from "react";

import { Skeleton, Stack } from "@mui/material";
import Grid from "@mui/material/Grid2";


export const InsightSkeleton: React.FC = () => {

  return (
    <Grid container spacing={2}>
      <Grid size={12} display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        <Skeleton
          variant="rounded"
          width={240}
          height={32}
        />
        <Stack direction="row" spacing={2}>
          <Skeleton
            variant="rounded"
            width={120}
            height={50}
          />
          <Skeleton
            variant="rounded"
            width={120}
            height={50}
          />
          <Skeleton
            variant="rounded"
            width={120}
            height={50}
          />
        </Stack>
      </Grid>
      <Grid size={{ xs: 6, lg: 4, xl: 3 }}>
        <Skeleton
          variant="rounded"
          height={160}
        />
      </Grid>
      <Grid size={{ xs: 6, lg: 4, xl: 3 }}>
        <Skeleton
          variant="rounded"
          height={160}
        />
      </Grid>
      <Grid size={{ xs: 6, lg: 4, xl: 3 }}>
        <Skeleton
          variant="rounded"
          height={160}
        />
      </Grid>
      <Grid size={{ xs: 6, lg: 4, xl: 3 }}>
        <Skeleton
          variant="rounded"
          height={160}
        />
      </Grid>
      <Grid size={{ xs: 6, lg: 4, xl: 3 }}>
        <Skeleton
          variant="rounded"
          height={160}
        />
      </Grid>
      <Grid size={{ xs: 6, lg: 4, xl: 3 }}>
        <Skeleton
          variant="rounded"
          height={160}
        />
      </Grid>
    </Grid>
  )

}
