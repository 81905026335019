import React, { useEffect, useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from '@mui/material/styles';

import { useAudienceContext } from "@/context";


interface CreateAudienceDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (audience_name: string) => void;
}

const ErrorTextField = styled(TextField)(({theme}) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '6px',
    '& fieldset': {
      borderColor: (props: { error: boolean }) => props.error && theme.palette.error.main,
    },
    '&:hover fieldset': {
      borderColor: (props: { error: boolean }) => props.error && theme.palette.error.main,
      borderWidth: '2px',
    },
    '&:focus fieldset': {
      borderColor: (props: { error: boolean }) => props.error && theme.palette.error.main,
      borderWidth: '2px',
    },
  },
}));

const CreateAudienceDialog: React.FC<CreateAudienceDialogProps> = (
  {
    open,
    onClose,
    onConfirm
  }
) => {

  const { state, dispatch } = useAudienceContext();

  const [audienceName, setAudienceName] = useState<string>("");
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    state.audiences?.find((audience) => audience.audience_name === audienceName)
    ? setError(true)
    : setError(false)
  }, [audienceName, state])

  const handleAudienceNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError(false);
    setAudienceName(e.target.value);
  };

  const handleSaveAudience = () => {
    dispatch({type: 'CHANGE_NAME', payload: audienceName})
    onConfirm(audienceName);
  }

  return (
    <Dialog
      open={open}
      disableEnforceFocus
      aria-labelledby="save-dialog"
    >
      <DialogTitle>
        <Typography>Name your Audience</Typography>
      </DialogTitle>

      <DialogContent>
        <Stack spacing={2}>
          <Typography>
            You need to name your audience before proceeding
          </Typography>
          <Stack spacing={1}>
            <ErrorTextField
              error={error}
              variant="outlined"
              size="small"
              color="primary"
              placeholder="Audience Name"
              value={audienceName}
              onChange={handleAudienceNameChange}
            />
            {error &&
              <Typography variant="caption" color="error">Audience name already exists</Typography>
            }
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSaveAudience} disabled={error}>
          Save Audience
        </Button>
      </DialogActions>

    </Dialog>
  )
}

export default CreateAudienceDialog;
