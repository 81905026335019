import { Add, FilterList, ImportExport, RestartAlt } from '@mui/icons-material';
import {
  Alert,
  Button,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';
import {
  AudienceCard,
  ButtonIcon,
  CreateAudienceCard,
  FilterBox,
  SearchBox,
} from '@/components/atoms';
import React, { useEffect, useState } from 'react';
import {
  audienceFilterOptions,
  audienceSortOptions,
  defaultAudience,
  filterAudiences,
} from '@/utils';

import { Audience } from '@/types';
import { AudienceSkeleton } from './AudienceSkeleton';
import { DeleteAudienceDialog } from '@/components/molecules';
import Grid from '@mui/material/Grid2';
import { PageContainer } from '@/components/containers';
import { useAudienceContext } from '@/context';
import { useAudiences } from '@/hooks';
import { useNavigate } from 'react-router-dom';

const AudiencePage: React.FC = () => {
  const { dispatch } = useAudienceContext();
  const navigate = useNavigate();

  const { audiences, loading, error } = useAudiences('build');

  const [rows, setRows] = useState<Audience[]>([]);
  const [selectedAudience, setSelectedAudience] = useState<Audience | null>(
    null
  );
  const [searchText, setSearchText] = useState<string>('');
  const [sortBy, setSortBy] = useState<string>('');
  const [filterBy, setFilterBy] = useState<string>('');

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  useEffect(() => {
    const filteredAudiences = filterAudiences(
      audiences,
      searchText,
      filterBy,
      sortBy
    );

    if (filteredAudiences) setRows([defaultAudience, ...filteredAudiences]);
  }, [audiences, searchText, filterBy, sortBy]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) =>
    setSearchText(event.target.value || '');

  const handleSortSelect = (event: SelectChangeEvent) =>
    setSortBy(event.target.value as string);

  const handleFilterSelect = (event: SelectChangeEvent<typeof filterBy>) => {
    setFilterBy(event.target.value);
  };

  const handleReset = () => {
    setSearchText('');
    setFilterBy('');
    setSortBy('');
  };

  const handleAudienceCreate = () => {
    dispatch({ type: 'CREATE_AUDIENCE' });
    navigate('new');
  };

  const handleAudienceSelect = (audience: Audience) => {
    dispatch({ type: 'GET_AUDIENCE', payload: audience });
    navigate(audience.audience_id);
  };

  const handleAudienceInsightsSelect = (audience: Audience) => {
    dispatch({ type: 'GET_AUDIENCE', payload: audience });
    navigate(`${audience.audience_id}/insight`);
  };

  const handleAudienceDeleteSelect = (audience: Audience) => {
    setSelectedAudience(audience);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setSelectedAudience(null);
  };

  const actionButtons = (
    <Stack direction="row" spacing={2}>
      <Button
        variant="contained"
        size="small"
        disableElevation
        startIcon={<Add />}
        onClick={handleAudienceCreate}
      >
        Create New
      </Button>
    </Stack>
  );

  return (
    <PageContainer actions={actionButtons}>
      <Grid container spacing={2}>
        <Grid size={12}>
          <Typography>My Audiences</Typography>
        </Grid>
        <Grid container size={12} alignItems="center">
          <Grid>
            <SearchBox value={searchText} onSearch={handleSearch} />
          </Grid>
          <Grid>
            <FilterBox
              label="Filter"
              value={filterBy}
              options={audienceFilterOptions}
              onChange={handleFilterSelect}
              startIcon={<FilterList fontSize="small" />}
              showSelected
            />
          </Grid>
          <Grid>
            <FilterBox
              label="Sort"
              value={sortBy}
              options={audienceSortOptions}
              onChange={handleSortSelect}
              startIcon={<ImportExport fontSize="small" />}
              showSelected
            />
          </Grid>
          <Grid>
            <ButtonIcon
              onClick={handleReset}
              color="info"
              size={38}
              Icon={RestartAlt}
              tooltip="Reset Filters"
            />
          </Grid>
        </Grid>

        <Grid container size={12}>
          {loading ? (
            <AudienceSkeleton />
          ) : error ? (
            <Grid>
              <Alert variant="filled" severity="error">
                It was not possible to load your audiences at this time, please
                try again.
              </Alert>
            </Grid>
          ) : (
            rows.map((audience, index) => (
              <Grid key={index}>
                {index === 0 ? (
                  <CreateAudienceCard onClick={handleAudienceCreate} />
                ) : (
                  <AudienceCard
                    audience={audience}
                    onEdit={handleAudienceSelect}
                    onInsights={handleAudienceInsightsSelect}
                    onDelete={handleAudienceDeleteSelect}
                  />
                )}
              </Grid>
            ))
          )}
        </Grid>
      </Grid>
      {selectedAudience && (
        <DeleteAudienceDialog
          audience={selectedAudience}
          open={deleteDialogOpen}
          onClose={handleCloseDeleteDialog}
        />
      )}
    </PageContainer>
  );
};

export default AudiencePage;
