import React from "react";

import {
  AppBar,
  Box,
  Drawer,
  IconButton,
  Stack,
  Toolbar
} from "@mui/material";
import { MenuOpen } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";

import { Logo, LogoDark } from "@/assets/logos";
import {Spinner} from "@/components/atoms";
import {Outlet} from "react-router-dom";



const LoadingLayout: React.FC = () => {

  const theme = useTheme();
  const mode = theme.palette.mode;

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        overflow: 'hidden',
        height: '100vh',
        width: '100vw',
        minWidth: '1028px'
      }}
    >
      <AppBar
        color="inherit"
        position="absolute"
        sx={{ zIndex: theme.zIndex.drawer + 1 }}
      >
        <Toolbar variant="dense" sx={{ mx: -1 }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              flexWrap: 'wrap',
              width: '100%',
            }}
          >
            <Stack direction="row" spacing={2} >
              <IconButton>
                <MenuOpen />
              </IconButton>
              <Box sx={{ flexGrow: 0, width: "148px", height: "32px", mr: 2 }}>
                {mode === "dark" ? <Logo /> : <LogoDark />}
              </Box>
            </Stack>
          </Stack>
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
        }}
      >
        <Toolbar variant="dense" sx={{ displayPrint: 'none' }} />
        <Box
          component="main"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'hidden',
            width: '100%',
            height: '100vh',
            minWidth: '1028px'
          }}
        >
          <Spinner />
        </Box>
      </Box>

    </Box>
  )

}


export default LoadingLayout;
