import React from "react";

import {
  Box,
  Stack,
  Typography
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { toRounded } from "@/utils";


interface SearchBoxProps {
  name: string;
  value: number;
  valueFormatter?: (value: number) => number | string;
  icon?: React.ReactNode;
}


const StatBox: React.FC<SearchBoxProps> = ({name, value, valueFormatter = toRounded, icon}) => {

  const theme = useTheme();

  return (
    <Stack
      spacing={2}
      border={1}
      borderRadius={1}
      borderColor={theme.palette.divider}
      p={2}
      minWidth={"144px"}
    >
      <Stack spacing={1} direction="row" alignItems="flex-end">
        {icon}
        <Typography variant="body2">{name}</Typography>
      </Stack>
      <Box display="flex" justifyContent="center">
        <Typography variant="h4">{valueFormatter(value)}</Typography>
      </Box>
    </Stack>
  )
}

export default StatBox;
