import {
  ADD_VARIABLE_GROUP,
  BUILD_AUDIENCE,
  CHANGE_BUILD_MODE,
  CHANGE_GROUP_OPERATOR,
  CHANGE_GROUP_VARIABLES,
  CHANGE_NAME,
  CHANGE_OPERATOR,
  CREATE_AUDIENCE,
  CREATE_INSIGHT,
  DELETE_AUDIENCE,
  GET_AUDIENCE,
  GET_AUDIENCE_LIST,
  REMOVE_VARIABLE_GROUP,
  RESET_AUDIENCE_STATUS,
  RESET_VARIABLE_GROUP,
  SAVE_AUDIENCE,
  UPDATE_AUDIENCE,
} from "./AudienceActions";
import {Audience, AudienceSummary, Insight, SavedAudience, Variable} from "@/types";

export type Action =
  | { type: typeof CREATE_AUDIENCE; }
  | { type: typeof GET_AUDIENCE; payload: Audience }
  | { type: typeof GET_AUDIENCE_LIST; payload: Audience[] }
  | { type: typeof RESET_AUDIENCE_STATUS; }
  | { type: typeof BUILD_AUDIENCE; payload: {audience_id: string, audience_size: AudienceSummary[]} }
  | { type: typeof CREATE_INSIGHT; payload: {audience: Audience, insight: Insight} }
  | { type: typeof SAVE_AUDIENCE; payload: SavedAudience }
  | { type: typeof UPDATE_AUDIENCE; payload:  {audiences: Audience[], current: Audience} }
  | { type: typeof DELETE_AUDIENCE; payload: Audience[] }
  | { type: "LOADING"; }
  | { type: "ERROR"; payload: string }
  | { type: "RESET"; }
  | { type: typeof ADD_VARIABLE_GROUP; }
  | { type: typeof REMOVE_VARIABLE_GROUP; payload: number }
  | { type: typeof RESET_VARIABLE_GROUP; payload: number }
  | { type: typeof CHANGE_OPERATOR; payload: string }
  | { type: typeof CHANGE_BUILD_MODE; payload: string }
  | { type: typeof CHANGE_GROUP_OPERATOR; payload: {id: number, operator: string} }
  | { type: typeof CHANGE_GROUP_VARIABLES; payload: {id: number, variables: Variable[]} }
  | { type: typeof CHANGE_NAME; payload: string }


export interface AudienceState {
  audiences: Audience[] | null;
  current: Audience | null;
  insight: Insight | null;
  loading: boolean;
  change: boolean;
  built: boolean;
  error: string | null;
}

export const initialState: AudienceState = {
  audiences: null,
  current: null,
  insight: null,
  loading: false,
  change: false,
  built: false,
  error: null
}
