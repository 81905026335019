import React from "react";

import {
  Box,
  Stack,
  Typography
} from '@mui/material';

import { PageContainer } from "@/components/containers";


const PlaceholderPage: React.FC = () => {
  return (
    <PageContainer>
      <Box
        display="flex"
        justifyContent="center"
        height="calc(100vh - 57px - 48px - 32px)"
      >
        <Stack spacing={4} mt={10}>
          <Typography
            variant="h2"
            sx={{
              fontFamily: 'cera-pro-bold'
            }}
          >
            —COMING SOON—
          </Typography>
          <Typography>
            This is a new feature that is being worked on and will be available soon
          </Typography>
        </Stack>
      </Box>
    </PageContainer>
  );
};

export default PlaceholderPage;
