import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";

import { Alert, SelectChangeEvent, Typography } from '@mui/material';
import Grid from "@mui/material/Grid2";

import { DiscoverSkeleton } from "./DiscoverSkeleton";
import { AudienceCard, ButtonIcon, FilterBox, SearchBox} from "@/components/atoms";
import { PageContainer } from "@/components/containers";
import { useAudiences } from "@/hooks";
import { audienceSortOptions, filterAudiences } from "@/utils";
import { ImportExport, RestartAlt } from "@mui/icons-material";
import { Audience } from "@/types";
import { useAudienceContext } from "@/context";


const DiscoverPage: React.FC = () => {

  const { dispatch } = useAudienceContext();
  const navigate = useNavigate();

  const { audiences, loading, error } = useAudiences('mindset');

  const [rows, setRows] = useState<Audience[]>([]);
  const [searchText, setSearchText] = useState<string>("");
  const [sortBy, setSortBy] = useState<string>("");

  useEffect(() => {
    const filteredAudiences = filterAudiences(
      audiences,
      searchText,
      undefined,  // No filter
      sortBy
    );

    if (filteredAudiences) setRows(filteredAudiences);
  }, [audiences, searchText, sortBy]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) =>
    setSearchText(event.target.value || "");

  const handleSortSelect = (event: SelectChangeEvent) =>
    setSortBy(event.target.value as string);

  const handleReset = () => {
    setSearchText("");
    setSortBy("");
  };

  const handleAudienceSelect = (audience: Audience) => {
    dispatch({ type: "GET_AUDIENCE", payload: audience });
    navigate(`${audience.audience_id}/insight`);
  }

  return (
    <PageContainer>
      <Grid container spacing={2}>
        <Grid size={12}>
          <Typography>Mindsets</Typography>
        </Grid>
        <Grid container size={12} alignItems="center">
          <Grid>
            <SearchBox
              value={searchText}
              onSearch={handleSearch}
            />
          </Grid>
          <Grid>
            <FilterBox
              label="Sort"
              value={sortBy}
              options={audienceSortOptions}
              onChange={handleSortSelect}
              startIcon={<ImportExport fontSize="small"/>}
              showSelected
            />
          </Grid>
          <Grid>
            <ButtonIcon
              onClick={handleReset}
              color="info"
              size={38}
              Icon={RestartAlt}
              tooltip="Reset Filters"
            />
          </Grid>
        </Grid>

        <Grid container size={12}>
          {loading ? (
            <DiscoverSkeleton />
          )
            : error ? (
              <Grid>
                <Alert variant="filled" severity="error">
                  It was not possible to load your audiences at this time, please try again.
                </Alert>
              </Grid>
            )
              : rows.map((audience, index) => (
                <Grid key={index}>
                  <AudienceCard
                    audience={audience}
                    onEdit={handleAudienceSelect}
                  />
                </Grid>
              ))
          }
        </Grid>
      </Grid>
    </PageContainer>
  );
};

export default DiscoverPage;
