import React from 'react';

import { Stack, Typography, TypographyVariant } from "@mui/material";
import { Info } from "@mui/icons-material";

import { HelpBox } from "@/components/atoms";


type HelpTextProps = {
  text: string;
  helpText: string;
  variant?: TypographyVariant;
};

const HelpText: React.FC<HelpTextProps> = (
  {
    text,
    helpText,
    variant = "body1"
  }
) => {

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Typography variant={variant}>{text}</Typography>
      <HelpBox title={helpText} placement="right">
        <Info fontSize="small" color="info"/>
      </HelpBox>
    </Stack>
  )

}

export default HelpText;
