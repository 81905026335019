import React, { useCallback, useMemo } from "react";
import { useSearchParams, useLocation, useNavigate } from 'react-router-dom';

import { ThemeContainer, AuthContainer } from "@/components/containers";
import { Navigate, Navigation, Router  } from "@/types";
import { NavigationContext, RouterContext } from "@/context";

interface AppContainerProps {
  children?: React.ReactNode;
  navigation?: Navigation;
}

const AppContainer: React.FC<AppContainerProps> = (
  {
    children,
    navigation = [],
  }
) => {

  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const navigateImpl = useCallback<Navigate>(
    (url, { history = 'auto' } = {}) => {
      if (history === 'auto' || history === 'push') {
        return navigate(url);
      }
      if (history === 'replace') {
        return navigate(url, { replace: true });
      }
      throw new Error(`Invalid history option: ${history}`);
    },
    [navigate],
  );

  const router = useMemo<Router>(
    () => ({
      pathname,
      searchParams,
      navigate: navigateImpl,
    }),
    [pathname, searchParams, navigateImpl],
  );

  return (
    <AuthContainer>
      <ThemeContainer>
        <RouterContext.Provider value={router}>
          <NavigationContext.Provider value={navigation}>
            {children}
          </NavigationContext.Provider>
        </RouterContext.Provider>
      </ThemeContainer>
    </AuthContainer>
  )

}

export default AppContainer
