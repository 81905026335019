import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

import { Box, Button, Container, Stack, Typography } from '@mui/material';
import { useLocation, useNavigate } from "react-router-dom";

import { ActionBar, LoadingOverlay } from "@/components/atoms";
import { Breadcrumb } from "@/types";
import { checkPermission, getRootPath } from "@/utils";

interface PageContainerProps {
  children: React.ReactNode;
  breadcrumbs?: Breadcrumb[];
  actions?: React.ReactElement;
  scope?: string;
  loading?: boolean;
  loadingText?: string;
}

const PageContainer: React.FC<PageContainerProps> = (
  {
    children,
    breadcrumbs,
    actions,
    scope,
    loading,
    loadingText
  }
) => {

  const { user } = useAuth0();
  const location = useLocation();
  const navigate = useNavigate();

  const hasPagePermission = user ? checkPermission(user, getRootPath(location.pathname)) : false;
  const hasScopePermission = user && scope ? checkPermission(user, scope) : true;
  const hasPermission = hasPagePermission && hasScopePermission;

  return (
    <>
      {loading && (
        <LoadingOverlay open={loading} text={loadingText} />
      )}
      {hasPermission ?
        <>
          <ActionBar breadcrumbs={breadcrumbs} actions={actions}/>
          <Box
            sx={{
              height: '100%',
              overflowY: 'auto'
            }}
          >
            <Container
              maxWidth={false}
              sx={{
                p: 2,
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
              }}
            >
              {children}
            </Container>
          </Box>
        </>
      :
        <Box
          sx={{
            height: '50%',
            p: 2,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Stack
            spacing={2}
            alignItems="center"
          >
            <Typography>
              You do not have permissions to view this page.
            </Typography>
            <Stack
              spacing={2}
              direction="row"
            >
              <Button
                variant="contained"
                color="info"
                onClick={() => navigate(-1)}
              >
                Go Back
              </Button>
              <Button
                variant="contained"
                onClick={() => navigate('/')}
              >
                To Home Page
              </Button>
            </Stack>
          </Stack>
        </Box>
      }
    </>
  )
}

export default PageContainer;
