import { AppContainer, AuthContainer } from '@/components/containers';
import {
  BubbleChart,
  Explore,
  Person,
  RocketLaunch,
  UploadFile,
} from '@mui/icons-material';

import { AudienceProvider } from '@/context';
import { Navigation } from '@/types';
import { Outlet } from 'react-router-dom';
import React from 'react';

const NAVIGATION: Navigation = [
  {
    segment: 'audience',
    title: 'Audiences',
    icon: <Person />,
    pattern: 'audience/:id?/:segment?',
  },
  {
    segment: 'upload',
    title: 'Uploads',
    icon: <UploadFile />,
    pattern: 'upload/:id?/:segment?',
  },
  {
    segment: 'activation',
    title: 'Activations',
    icon: <RocketLaunch />,
  },
  {
    segment: 'discover',
    title: 'Discover',
    icon: <BubbleChart />,
    pattern: 'discover/:id?/:segment?',
  },
  {
    segment: 'explore',
    title: 'Explore',
    icon: <Explore />,
  },
];

function App() {
  return (
    <AuthContainer>
      <AudienceProvider>
        <AppContainer navigation={NAVIGATION}>
          <Outlet />
        </AppContainer>
      </AudienceProvider>
    </AuthContainer>
  );
}

export default App;
