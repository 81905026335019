import React from "react";

import { Button, Card, CardActions, CardContent, CardHeader, Typography } from "@mui/material"


interface MenuCardProps {
  title: string;
  text: string;
  onClick: () => void;
  icon?: React.ReactNode;
  buttonText?: string;
  disabled?: boolean;
  width?: string;
  height?: string;
}

export const MenuCard: React.FC<MenuCardProps> = (
  {
    title,
    text,
    onClick,
    icon,
    buttonText,
    disabled = false,
    width,
    height
  }) => {

  return (
    <Card
      sx={{
        width: width ?? '236px',
        height: '100%'
        // height: height ?? '344px'
    }}
    >
      <CardHeader
        avatar={
          icon ? icon : null
        }
        title={title}
        slotProps={{
          title: {
            variant: 'body1'
          }
        }}
      />
      <CardContent sx={{ height: "calc(100% - 48px - 56px)" }}>
        <Typography variant="body2">{text}</Typography>
      </CardContent>
      <CardActions sx={{ justifyContent: "flex-end" }}>
        <Button
          variant="contained"
          size="small"
          onClick={onClick}
          disabled={disabled}
        >
          {buttonText || 'Select'}
        </Button>
      </CardActions>
    </Card>
  )
}

export default MenuCard;
